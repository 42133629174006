import React, { memo, useState } from 'react';

import AddNewDevicePopup from '../../devicesComponent/addNewDevicePopup/AddNewDevicePopup';

import './AddDevicesButton.css';

type PropsType = {
    clusters: any[]
}

const AddDevicesButton = memo(({clusters}: PropsType) => {
    const [active, setActive] = useState(false);
    return (
        <>
            <button className="add_devices_button_cont" onClick={() => setActive(true)}>
                <img className="devices_button_img" src="/img/add_icon.svg" alt="" />
                <div className="add_devices_text">Add New Device</div>
            </button>
            <AddNewDevicePopup clusters={clusters} active={active} setActive={setActive}/>
        </>
    );
});

export default AddDevicesButton;
