import React, { useCallback, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import Layout from '../../../components/layout/Layout';
import { createModal } from '../../../components/Modals';
import { useAppDispatch } from '../../../hooks/redux';
import { makeFetch } from '../../../redux/helpers';
import { addUserData } from '../../../redux/reducers/UserSlice';
import { AuthContentBtn } from '../../signup/signupPage/Signup';

import './Login.css';

type LoginType = {
    login: string;
    password: string;
};

const Login = React.memo(() => {
    const navigation = useNavigate();

    const [loading, setLoading] = useState<boolean>(false);
    const [login, setLogin] = useState<string>('');
    const [password, setPassword] = useState<string>('');

    const dispatch = useAppDispatch();

    const acceptInvitation = useCallback(async () => {
        const { success, data } = await makeFetch(
            process.env.REACT_APP_ORG_URL +
            `/org/invitation/accept?id=${window.location.search.split('=')[1]}`,
            'GET',
            null,
            String(localStorage.getItem('__t'))
        );
        if (success) {
            const { success: modified } = await makeFetch(
                process.env.REACT_APP_AUTORIZE_URL + '/auth/lastwisited',
                'POST',
                { orgId: data.message },
                String(localStorage.getItem('__t'))
            );

            if (modified) {
                navigation('/');
                const loader = document.getElementById('loader-overflow') as HTMLElement;
                loader.style.display = 'block';
                setTimeout(() => {
                    loader.style.display = 'none';
                }, 1000);
            }
        }
    }, []);

    const handleKeyDown = (event: any) => {
        console.log(event);
        if (event.key === 'Enter') {
            console.log(event.key);
            event.preventDefault();
            event.target.blur();
        }
    };

    const loginAction = useCallback(async () => {
        if (!loading) {
            if (!login.includes('@') || !login.includes('.')) {
                return createModal({ error: false, message: 'Email not correct' });
            }

            if (!password.length) {
                return createModal({
                    error: false,
                    message: 'The field for the password cannot be empty'
                });
            }

            setLoading(true);
            const url = process.env.REACT_APP_AUTORIZE_URL + '/auth/login';
            const value = {
                email: login,
                password: password
            };
            const { success, data } = await makeFetch(url, 'POST', value);
            setLoading(false);

            if (success) {
                createModal({
                    error: false,
                    success: true,
                    message: 'You are successfully logged in'
                });
                localStorage.setItem('__account', 'true');
                localStorage.setItem('__t', data.token);
                localStorage.setItem('__r', data.refresh);
                dispatch(addUserData(data));
                if (!location.search.includes('invitation')) {
                    if (!data.lastWisitedOrg.length) return navigation("/freelance")
                    navigation('/');
                } else {
                    acceptInvitation();
                }
            } else {
                createModal({ error: true, message: data.message });
            }
        }
    }, [loading, login, password]);


    const passwordInput = useRef<HTMLInputElement>(null)

    return (
        <Layout>
            <div className="section_cont">
                <div className="text_cont">
                    <div className="sign_cont">
                        <h2>Login</h2>
                        <div>Login to start your work</div>
                    </div>
                </div>
            </div>
            <div id="form" style={{ marginTop: 38 }}>
                <div className="input_container">
                    <div>Email</div>
                    <div className="custom_input">
                        <div className="img_container">
                            <img src="img/authorization/mailInput.svg" alt="" />
                        </div>
                        <input
                            onKeyDown={(ev) => {
                                if (ev.key == 'Enter') {
                                    if (passwordInput !== null && passwordInput.current !== null) { passwordInput.current!.focus() }
                                }
                            }}
                            onChange={(e) => {
                                setLogin(e.target.value);
                            }}
                            type="text"
                            placeholder="youremail@email.com"
                        />
                    </div>
                </div>
                <div className="input_container">
                    <div>Password</div>
                    <div className="custom_input">
                        <div className="img_container">
                            <img src="img/authorization/lock.svg" alt="" />
                        </div>
                        <input
                            ref={passwordInput}
                            onChange={(e) => {
                                setPassword(e.target.value);
                            }}
                            type="password"
                            placeholder="********"
                            onKeyDown={(ev) => {
                                if (ev.key == 'Enter') {
                                    if (passwordInput !== null && passwordInput.current !== null) { loginAction() }
                                }
                            }}
                        />
                    </div>
                </div>
                <div className="forgot_container">
                    <button>Forgot your password?</button>
                </div>
                <div className="auth-btn-group">
                    {/* <button
                        style={{ backgroundColor: '#F8F8F8', color: '#485171', marginBottom: 10 }}
                        onClick={() => navigation('/login_as_organization')}
                        className="login_organization">
                        Login as organization member
                    </button> */}
                    <button
                        onClick={loginAction}
                    >
                        <AuthContentBtn loading={loading} text={'Login'} />
                    </button>
                </div>
            </div>
            <div className="signup_button_container">
                <div className="signup_button_text">
                    Don’t have account?
                    <Link style={{ marginLeft: 4 }} className="signup_link" to="/signup">
                        Sign Up
                    </Link>
                </div>
            </div>
        </Layout>
    );
});

export default Login;
