import React, { useEffect } from "react";
import { useCallback, useRef, useState } from "react";
import Convert from "ansi-to-html";
import chalk from "chalk";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import unenkiImp from "unenki";
const unenki = {
    strip: (str: string): string => {
        if (!str) return "";

        const avoid: string = unenkiImp.strip(str);
        const rep = avoid.replace(/\\u[0-9A-Fa-f]{4}/g, (match) => {
            return String.fromCharCode(parseInt(match.substring(2), 16))
        });
        return rep;
    }
};

import { useVirtualizer } from "@tanstack/react-virtual";

import { textColors } from "./constants";
import { autocomplete, osType, updateUsage } from "./data";
import { notif, onClear, onRemoveRows, onWriteRow } from "./handlers";

import "./index.styles.css";

const convert = new Convert({
    colors: {
        0: textColors.black,
        1: textColors.red,
        2: textColors.greenBright,
        3: textColors.yellow,
        4: textColors.blue,
        5: textColors.magenta,
        6: textColors.cyan,
        7: textColors.whileBright,
        8: textColors.whileBright,
        9: textColors.blueBright,
        10: textColors.magenta,
        11: textColors.magentaBright,
        12: textColors.cyan,
        13: textColors.cyanBright,
        14: textColors.white,
        15: textColors.whileBright
    }
})
const MOVING = 16 / 2.28

type props = {
    user?: string
    device?: string
    dir?: string
    isLeftData: boolean
    isWritable: boolean
    os: osType
}

declare global {
    interface Window {
        updateScroll: () => void
        di: () => HTMLDivElement
    }
}

function debounce(func: () => void, delay: number) {
    let timeoutId: NodeJS.Timeout;
    
    return function () {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(func, delay);
    };
  }

window.di = () => document.getElementsByClassName("web-sh-term")[0] as HTMLDivElement;
window.updateScroll = () => {
    debounce(() => {
        window.di().scrollBy({
            top: window.di().scrollHeight + 10000
        })
    }, 50)()
}

export const Wesh = ({user, device, dir, isLeftData, os, isWritable}: props) => {
    const [rows, setRows] = useState<string[]>([
        "  _____ ______ _____   ____ _______ ",
        " / ____|  ____|  __ \\ / __ \\__   __|",
        "| (___ | |__  | |  | | |  | | | |    ",
        " \\___ \\|  __| | |  | | |  | | | |  ",
        " ____) | |____| |__| | |__| | | |    ",
        "|_____/|______|_____/ \\____/  |_|",
 
        "┌──────────────────────────────────┐",
        "│ Welcome to SEDOT terminal window │",
        "│       Type \"help\" for help       │",
        "└──────────────────────────────────┘"
    ])

    const startData = `${chalk.blue(`[${user || "root"}@${device || "root"}`)} ${dir || "~"}${chalk.blue("]$")} `;

    //service info
    const [entryOnWriteOnce, setEntryOnWriteOnce] = useState<boolean>(false);
    const mainRef = useRef<HTMLDivElement | null>(null)
    const timeRef = useRef<number>(0);

    //cursor
    const cursorRef = useRef<HTMLDivElement | null>(null);

    //text input
    const inputRef = useRef<HTMLInputElement | null>(null);
    const [text, setText] = useState<string>("");

    //history
    const [history, setHistory] = useState<string[]>([""]);
    const [historyCursor, setHistoryCursor] = useState<number>(0)

    //autocomplete
    const [compl, setCompl] = useState<string[]>([]);
    const [complCursor, setComplCursor] = useState<number>(0);

    const virtualizer = useVirtualizer({
        count: rows.length,
        getScrollElement: () => mainRef.current,
        estimateSize: () => 45,
        enabled: true
    })

    const forceRowsUpdate = useCallback(({dir, row, len}: {dir: "left" | "right", row?: string[], len?: number}) => {
        console.log("update term view")
        if (dir == "right" && row) {
            // const s = rows.concat(...row);
            setRows(r => r.concat(...row));
        }

        if (dir == "left" && len) {
            const s = rows;
            // const sliced = s.slice(0, s.length - len);
            console.log((s.length - len) * -1, s.slice((s.length - len) * -1))
            setRows(r => r.slice((r.length - len) * -1))
        }
    }, [rows, setRows])

    //if user call write event
    const onWriteHandler = useCallback(() => {
        onWriteRow((row) => {
            forceRowsUpdate({dir: "right", row})
            window.updateScroll()
        })
    }, [rows, setRows, mainRef])

    const onRemoveRowsHandler = useCallback(() => {
        onRemoveRows((num) => {
            forceRowsUpdate({dir: "left", len: num})
            window.updateScroll()
        })
    }, [rows])

    const onClearRowsHandler = useCallback(() => {
        onClear(() => {
            setRows([])
        })
    }, [])

    useEffect(() => {
        if (!entryOnWriteOnce) {
            onWriteHandler()
            onRemoveRowsHandler()
            setEntryOnWriteOnce(true)
            onClearRowsHandler()
        }
    }, [])

    const onFocusStart = useCallback(() => {
        timeRef.current = Date.now();
    }, [])

    //event on terminal focus
    const onFocus = useCallback((e?: React.MouseEvent<HTMLDivElement>) => {
        const timer = Date.now() - timeRef.current;
        if (timer > 110) {
            return;
        }

        if (inputRef.current != null) {
            inputRef.current.focus();
            inputRef.current.click();
        }
    }, [inputRef])

    //moves to the next prediction variant
    const autocomMove = useCallback((type: "left" | "right") => {
        if (type == "left") {
            const c = complCursor -1
            if (c >= 0) {
                setComplCursor(c)
            }
        } else {
            const c = complCursor +1;
            if (c < compl.length) {
                setComplCursor(c)
            }
        }
    }, [complCursor, compl])

    const onHistory = useCallback((type: "up" | "down") => {
        if (inputRef.current == null) {
            return;
        }
        if (history.length == 0) {
            return;
        }

        let curr = historyCursor;
        
        if (type == "up") {
            if (historyCursor < history.length -1) {
                curr += 1;
            }
        } else {
            if (historyCursor > 0) {
                curr -= 1;
            }
        }

        if (curr > 0) {
            inputRef.current.value = history[curr -1];
            setHistoryCursor(curr)
            setText(history[curr-1]);
        } else {
            setText("");
            inputRef.current.value = "";
            setHistoryCursor(0)
        }
    }, [history, historyCursor, inputRef])

    //event on move cursor pointer
    const onCursor = useCallback((type: "left" | "right") => {
        if (compl.length) {
            return autocomMove(type);
        }
        if (cursorRef.current != null) {
            let position = Number(cursorRef.current.style.marginLeft.split("px")[0]);
            const t = unenki.strip(text);

            if (type == "left") {
                position = Math.round(position / - MOVING) > t.length -1 ? position : position - MOVING;
            } else {
                position = position + MOVING > 0 ? 0 : position + MOVING;
            }

            cursorRef.current.style.marginLeft = `${position}px`;
            return;
        }
    }, [cursorRef, text, compl, autocomMove])

    //event on enter is press
    const onPressEnter = useCallback(() => {
        window.updateScroll();
        if (inputRef.current != null && cursorRef.current != null) {
            let pushData = ""

            if (isLeftData) {
                pushData = startData
            }

            pushData += text;
            
            const newRows: string[] = rows;
            newRows.push(pushData)
            setRows(newRows);
            if (isLeftData) {
                setHistory((h) => [text, ...h])
            }
            updateUsage(os, unenki.strip(text))
            setText("")
            setCompl([])
            cursorRef.current.style.marginLeft = "0px";
            inputRef.current.value = "";
            notif("wesh_exec", unenki.strip(text))
        }
    }, [text, rows, notif, isLeftData, startData])

    const updateText = useCallback((value: string) => {
        if (!isWritable && inputRef.current != null) {
            inputRef.current.value = "";
            return;
        }
        if (compl.length) {
            setCompl([])
            setComplCursor(0)
        }

        const color = value.split(" ")
        if (color.length >= 1) {
            color[0] = chalk.red(color[0])
        }
        if (color.length >= 2) {
            color[1] = chalk.green(color[1])
        }

        setText(color.join(" "))
    }, [compl, isWritable])

    const predictNext = useCallback(async () => {
        onFocus();

        const c = unenki.strip(text);
        if (c.length == 0) {
            return;
        }
        const comm = c.split(" ");
        const d = await autocomplete(os, comm[comm.length -1]);
        
        if (comm[length -1] == d[0]) {
            return;
        }

        if (compl.length == 0) {
            setComplCursor(0)
            setCompl(d);
        } else {
            setCompl([])
            if (inputRef.current != null) {
                comm[comm.length -1] = d[complCursor]
                inputRef.current.value = comm.join(" ")
                updateText(inputRef.current.value)
            }
        }
    }, [text, compl, inputRef, complCursor, os])

    const onEscAutocom = useCallback(() => {
        setCompl([])
    }, [])

    const onInterrupt = useCallback(() => {
        updateText("^C")
        // onPressEnter()
    }, [onPressEnter, updateText])

    const specialHandler = useCallback((e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.ctrlKey && e.key.toLowerCase() == "c") {
            onInterrupt()
            return;
        }
        switch (e.key) {
            case "Escape":
                onEscAutocom()
                break;
            case "Tab":
                predictNext();
                e.preventDefault();
                e.stopPropagation();
                break;
            case "Enter":
                onPressEnter()
                return;
            case "ArrowLeft":
                onCursor("left")
                return;
            case "ArrowRight":
                onCursor("right")
                return;
            case "ArrowDown":
                onHistory("down")
                break;
            case "ArrowUp":
                onHistory("up")
                break;
            default:
                break;
        }
    }, [onCursor, predictNext, onHistory, onInterrupt, onPressEnter, updateText])

    const t = unenki.strip(text).split(" ");

    return (
        <div
            ref={mainRef}
            onMouseDown={onFocusStart}
            onMouseUp={(e) => {onFocus(e)}}
            className="web-sh-term"
            style={{
                contain: "strict"
            }}
        >
            <div
                className="web-sh-term-inner"
                style={{
                    height: virtualizer.getTotalSize()
                }}
            >
                {rows.map((entry, key) => {
                    return (
                        <section key={key} className="wsht-row">
                            <span dangerouslySetInnerHTML={{__html: convert.toHtml(entry)}}></span>
                        </section>
                    )
                })}
                <section className="wsht-row">
                    <div className="wsht-input-row">
                        <span>
                            {isLeftData ? (
                                <div className="nowr" style={{userSelect: "none"}} dangerouslySetInnerHTML={{__html: convert.toHtml(startData)}}/>
                            ) : (<></>)}
                            <div
                                className="nowr"
                                dangerouslySetInnerHTML={{__html: convert.toHtml(text)}}
                            />
                            <span className="wesh-comp-hil">{compl.length ? compl[complCursor].slice(t[t.length-1].length) : ""}</span>
                        </span>
                        <div
                            ref={cursorRef}
                            className="wsht-cursor"
                            style={{margin: 0}}
                        />
                    </div>
                    <div className="wesh-auto">
                        {compl.map((entry, i) => (
                            <span
                                key={i}
                            >{entry}</span>
                        ))}
                    </div>
                    <input
                        ref={inputRef}
                        style={{opacity: "0", height: 0, padding: 0, margin: 0, border: "none"}}
                        onKeyDown={(e) => {
                            specialHandler(e)
                        }}
                        onChange={(e) => {
                            updateText(e.currentTarget.value)
                        }}
                    />
                </section>
            </div>
        </div>
    )
}