import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { useAppSelector } from '../../hooks/redux';
import { makeFetch } from '../helpers';

// type ScriptType = {
//     id: string;
//     description: string;
//     execute: string;
//     name: string;
//     system: string;
// };

type InitialType = {
    clusters: any[];
    loading: boolean;
};

const initialState: InitialType = {
    clusters: [],
    loading: false
};

export const fetchClusters = createAsyncThunk('clusters/get', async (orgId:string) => {
    const { success, data } = await makeFetch(
        process.env.REACT_APP_DEVICES_URL + '/cluster/org/' + orgId,
        'GET',
        null,
        String(localStorage.getItem("__t"))
    );
    if (success) {
        console.log(data.message)
        return data.message;

    } else {
        console.log(data);
    }
});

export const clustersSlice = createSlice({
    name: 'clusters',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchClusters.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchClusters.fulfilled, (state, action: any) => {
                state.loading = false;
                state.clusters = action.payload;
            });
    }
});

export const clustersSliceReducer = clustersSlice.reducer;
