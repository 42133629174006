import React from "react";
import { memo } from "react";

import "./LogoImg.css"

const LogoImg = memo(() => {
    return (
        <div className="logo_circle">
            <img src="/img/logo.svg" alt="logo" />
        </div>
    )
})

export default LogoImg