import React, { memo, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import MainLayout from "../../components/layout/mainLayout/Layout";
import { useAppSelector } from "../../hooks/redux";

import "./Freelance.styles.css";

export const Freelance = memo(() => {
    const navigate = useNavigate()

    const { lastWisitedOrg } = useAppSelector(state => state.userReducer)

    useEffect(() => {
        if (lastWisitedOrg.length) {
            navigate("/")
        }
    }, [lastWisitedOrg])

    return (
        <MainLayout className="freelance-menu">
            <p>
                You are not yet invited or have not yet created an organization
            </p>
        </MainLayout>
    )
})