import React, { memo, useCallback, useContext, useEffect, useRef, useState } from "react";
import chalk from "chalk";

import { TerminalContext } from "../../../context/terminalContext";
import { fromBaseString } from "../../../helpers/base64";
import { useAppSelector } from "../../../hooks/redux";
import { wesh } from "../../../utils/react-wesh";

import { ws } from "./SingleWebSocket";
import { Message, MessageBuilder, MessageType, SOUTMessage } from "./types";

import "./Terminal.css";

declare global {
    interface Window {
        termCommand: string;
        termConnected: boolean
        gateway: string
        termPrevMessageType: string
        termPrevMessageOutput: number
        termPrevMessageOutputData: string
    }
}

const os_adapter = {
    'Windows': "windows",
    'macOS': "macOS",
    'Linux': "linux",
    'Raspberry': "linux",
}

const sleep = async (ms: number) => new Promise(resolve => setTimeout(resolve, ms))

window.termCommand = ""
window.termConnected = false
window.termPrevMessageType = ""
window.termPrevMessageOutput = 0
window.termPrevMessageOutputData = ""
//ps aux --no-headers | awk '{ sum += $3 } END { if (sum > 100) sum = 100; printf("%.0fn", sum) }'
//ps aux --no-headers | awk '{ sum += $4 } END { printf("%.0fn", sum) }'
//df -h / | grep [0-9] | awk '{print $5}' | tr -d "%"

//top -l 1 -s 0 | awk '/CPU usage/ {printf "%.0f\\n", $8}'
//top -l 1 -s 0 | awk '/PhysMem/ {used=$8; free=$10; total=used+free; printf "%d\n", (used/total)*100}'
//iostat -d disk0 | tail -n1 | awk '{print 100 - $NF}' | awk '{printf "%.0f\n", $1}'

//wmic cpu get loadpercentage
// console.log(toBaseString(`iostat -d disk0 | tail -n1 | awk '{print 100 - $NF}' | awk '{printf "%.0f\n", $1}'`))
console.log(fromBaseString(`7b=22=24=74=79=70=65=22=3a=22=53=48=4e=44=22=2c=22=64=61=74=61=22=3a=22=65=6e=64=65=64=22=7d`))
const XTerm = memo(() => {
    const { email } = useAppSelector(state => state.userReducer)
    const { devices } = useAppSelector(state => state.deviceReducer)
    const [currentDir, setCurrentDir] = useState<string | undefined>()
    const terminalMounted = useRef<boolean>(false)
    const [commandOut, setCommandOut] = useState<boolean>(true);
    const [isWritable, setIsWritable] = useState<boolean>(false);
    const isFirstInit = useRef<boolean>(true)

    const { addLog } = useContext(TerminalContext)

    const execCommand = useCallback(async (comm: string) => {
        setCommandOut(true)
        switch (comm) {
            case "help":
                wesh.writeRow('HELP Page 1/1')
                wesh.writeRow('This is only emulation for terminal.')
                wesh.writeRow("For start running command on connected device just run command for example")
                wesh.writeRow('\r\n\x1b[1m  echo "Hello SEDOT"\x1b[0m')
                setCommandOut(false);
                setIsWritable(true);
                break;
            case "":
                setCommandOut(false)
                break;
            case "clear":
                wesh.clear()
                wesh.writeRow("\x1b[1mCleared\x1b[0m")
                setCommandOut(false)
                break;
            case "^C":
                ws().send(new MessageBuilder(MessageType.FINL).buildFinalize().toEncodedJSON());
                setIsWritable(true);
                setCommandOut(false);
                window.termPrevMessageType = MessageType.FINL
                break;
            default:
                window.termCommand = comm
                if (window.termPrevMessageType != MessageType.SOUT) {
                    ws().send(
                        new MessageBuilder(MessageType.STRT)
                            .buildStart(comm)
                            .toEncodedJSON()
                    )
                } else {
                    console.log("[ INFO ] Write to stdin")
                    ws().send(
                        new MessageBuilder(MessageType.ShellIn)
                            .buildStart(comm+"\n")
                            .toEncodedJSON()
                    )
                }
            break;
        }
    }, [wesh])

    const getMessage = async (e: MessageEvent) => {
        const message: Message = new Message(e.data);
        switch (message.$type) {
            case MessageType.ACPT:
                console.log("[ INFO ] Connection to remote device was accepted")
                // setInterval(() => {
                //     if (ws().readyState != ws().OPEN) {return}
                //     ws().send(
                //         new MessageBuilder(MessageType.PING).buildPing("ping").toEncodedJSON()
                //     )
                //     console.log("[ INFO ] PING")
                // }, 5000)
                break
            case MessageType.CNDE:
                setIsWritable(false)
                terminalMounted.current = false
                setCommandOut(true)
                wesh.writeRow("Connection refused... Trying again in 15s")
                await ws.reconnect();
                check()
                break;
            case MessageType.OEND:
                window.termCommand = ""
                setCommandOut(false);
                setIsWritable(true);
                break
            case MessageType.PONG:
                console.log("[ INFO ] PONG")
                return
            case MessageType.SOUT:
                window.termPrevMessageOutputData += message.dataGetter<SOUTMessage>().output
                if (window.termPrevMessageType == MessageType.SOUT) {
                    wesh.writeBatch(message.dataGetter<SOUTMessage>().output);
                } else {
                    wesh.writeBatch(message.dataGetter<SOUTMessage>().output);
                }
                if (window.termPrevMessageOutput == 0) {
                    window.termPrevMessageOutput = message.dataGetter<SOUTMessage>().output.split("\n").length
                }
                break
            case MessageType.ShellERR:
                wesh.writeBatch(chalk.red(message.dataGetter<SOUTMessage>().output));
                window.termPrevMessageOutput == 0
                setCommandOut(false)
                setIsWritable(true)
                break
            case MessageType.PWDChange:
                setCurrentDir(message.dataGetter<string>().split("/").pop())
                break
            case MessageType.ShellEND:
                addLog({
                    id: "Pending...",
                    title: "Command",
                    time: Date.now(),
                    timeAgo: "",
                    timefull: "",
                    command: window.termCommand,
                    output: window.termPrevMessageOutputData
                })
                window.termPrevMessageOutputData = ""
                window.termPrevMessageOutput == 0
                window.termCommand = ""
                setCommandOut(false)
                setIsWritable(true)
                break
            default:
                // checkPrev(e)
                break
        }
        window.termPrevMessageType = message.$type
    }

    const loadTerminal = useCallback(async () => {
        if (!email.length) {return}
        console.log("e: " + window.termConnected)
        if (!terminalMounted.current) {
            terminalMounted.current = true

            const configMessage: string = new MessageBuilder(MessageType.CONF).buildConfig({
                user: email,
                device: window.location.search.split("=")[1]
            }).toEncodedJSON();
            ws().send(configMessage)
            setIsWritable(true)
            setCommandOut(false)
            window.termCommand = ""

            ws().addEventListener("message", (e) => {getMessage(e)})
            ws().addEventListener("close", async () => {
                setIsWritable(false)
                terminalMounted.current = false
                setCommandOut(true)
                wesh.writeRow("Connection closed... Trying to reconnect in 15s")
                await sleep(15000)
                return check()
            })
            if (isFirstInit.current) {
                wesh.onExec(execCommand)
            }
            isFirstInit.current = false
        }
    }, [terminalMounted, email, execCommand, isFirstInit])

    const check = useCallback(async (): Promise<any> => {
        console.log(ws().readyState)
        if (ws().readyState == ws().OPEN && email.length) {
            loadTerminal()
            return
        } else {
            wesh.writeRow(isFirstInit ? "Connecting to remote device..." : "Connection closed... Trying to reconnect in 15s")
            await ws.reconnect();
            await sleep(isFirstInit ? 5000 : 15000)
        }

        return check()
    }, [email, loadTerminal, isFirstInit])

    useEffect(() => {
        console.log(email)
        if (email.length) {
            check()
        }
    }, [email])

    return (
        <wesh.Term
            isLeftData={!commandOut}
            os={os_adapter[devices.find(d => d.id == window.location.search.split("=")[1])?.system || "Linux"] as any}
            user={email}
            isWritable={isWritable}
            dir={currentDir}
        />
    )
})

const Terminal = memo(() => {
    return (
        <div className="terminal_cont">
            <XTerm />
        </div>
    )
})

export default Terminal