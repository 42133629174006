import React, { memo, useCallback, useEffect, useState } from 'react';

import { Loader } from '../../../components/devicesComponent/listDevicesLog/ListDevicesLog';
import MainLayout from '../../../components/layout/mainLayout/Layout';
import AddScriptButton from '../../../components/marketplaceComponent/addScriptButton/AddScriptButton';
import FiltersComponent from '../../../components/marketplaceComponent/filter/FiltersComponent';
import ScriptPlate from '../../../components/marketplaceComponent/scriptPlate/ScriptPlate';
import NameSection from '../../../components/sectionsFilling/nameSection/NameSection';
import SearchArea from '../../../components/sectionsFilling/search/Search';
import usePermissions from '../../../helpers/checkPermission';
import { useAppSelector } from '../../../hooks/redux';
import { permissionsName } from '../../../permissionsData/permissions';
import { makeFetch } from '../../../redux/helpers';
import { fetchScripts } from '../../../redux/reducers/ScriptsOrgSlice';
import { useAppDispatch } from '../../../redux/store';

import './Scripts.css';

const Scripts = memo(() => {
    const dispatch = useAppDispatch();

    const {checkPermission} = usePermissions()

    const { lastWisitedOrg } = useAppSelector((state) => state.userReducer);
    const { scripts } = useAppSelector((state) => state.scriptsOrgSliceReducer);

    const [clear, setClear] = useState<boolean>(false);

    const findAction = () => {
        console.log('hello');
    };

    const [arrayScripts, setArrayScripts] = useState<any[]>([]);
    const [loadingScripts, setLoadingScripts] = useState<boolean>(false);

    const [orgScriptsArr, setOrgScriptsArr] = useState<string[]>([]);

    useEffect(() => {
        const scriptsInOrg: string[] = [];
        if (scripts.length) {
            scripts.map((entry) => {
                scriptsInOrg.push(entry.id);
            });
            setOrgScriptsArr(scriptsInOrg);
        }
    }, [scripts]);

    useEffect(() => {
        if (lastWisitedOrg) {
            dispatch(fetchScripts(lastWisitedOrg));
        }
    }, [lastWisitedOrg]);

    useEffect(() => {
        document.title = 'SEDOT | Marketplace';
    }, []);

    const getScriptAction = useCallback(async () => {
        setLoadingScripts(true);
        const { success, data } = await makeFetch(
            `${process.env.REACT_APP_MARKETPLACE_URL}/marketplace/all?limit=50&offset=0`,
            'POST',
            null,
            String(localStorage.getItem('__t'))
        );
        if (success) {
            setArrayScripts(data.message);
        } else {
            setLoadingScripts(false)
            console.log(data);
        }
        setLoadingScripts(false);
    }, []);

    useEffect(() => {
        getScriptAction();
    }, []);

    return (
        <>
            <MainLayout>
                <div className="marketplace_scripts_container">
                    <div className="marketplace_scripts_button_container">
                        <SearchArea setClear={setClear} findAction={findAction} />
                        {checkPermission(permissionsName.marketplace.add) && <AddScriptButton refreshData={getScriptAction} />}
                    </div>
                    <div className="marketplace_scripts_content">
                        <div className="marketplace_scripts">
                            {!arrayScripts.length ? (
                                loadingScripts ? (
                                    <div className="loader_grid_cont">
                                        <div
                                            style={{ display: 'block' }}
                                            className="animation_devices_container">
                                            <img
                                                className="animation_devices_loading"
                                                src="./img/loader.svg"
                                            />
                                        </div>
                                    </div>
                                ) : (
                                    <div className="script_plate_container no_script_text">
                                        There are no scripts at the moment
                                    </div>
                                )
                            ) : (
                                arrayScripts.map((entry, key) => (
                                    <ScriptPlate
                                        key={key}
                                        id={entry.id}
                                        title={entry.name}
                                        description={entry.description}
                                        os={entry.system}
                                        time={entry.time}
                                        showGreeAdd={
                                            orgScriptsArr.includes(entry.id) ? false : true
                                        }
                                    />
                                ))
                            )}
                        </div>
                        <div className="marketplace_filters">
                            <div className="marketplace_filters_cont">
                                <div style={{ margin: '27px 0 0 24px' }}>
                                    <NameSection title="Filters" subtitle="Search fast" />
                                </div>
                                <div className="marketplace_filters_inner">
                                    <FiltersComponent />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </MainLayout>
        </>
    );
});

export default Scripts;
