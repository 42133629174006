import React, { memo, useEffect, useState } from "react";

import usePermissions from "../../../helpers/checkPermission";
import { permissionsName } from "../../../permissionsData/permissions";
import NameSection from "../../sectionsFilling/nameSection/NameSection";
import CreateClusterPopup from "../createClusterPopup/CreateClusterPopup";
import DevicesLog from "../devicesLog/DevicesLog";
import SettingsClusterPopup from "../settingsClusterPopup/SettingsClusterPopup";

import "./DevicesClaster.css"

type PropsType = {
    title: string,
    all: number,
    online?: number,
    offline?: number,
    devices: any[],
    id: string
}

const DevicesClaster = memo(({ id, title, all, online, offline, devices }: PropsType) => {

    const {checkPermission} = usePermissions()

    const [logType, setLogType] = useState("claster_min")
    const [check, setCheck] = useState(false)
    const [active, setActive] = useState<boolean>(false)

    const checkAction = () => {
        if (check) {
            setCheck(false)
        } else {
            setCheck(true)
        }

    }

    const logAction = () => {
        if (logType === 'claster_min') {
            setLogType("claster_max")
        }
        else {
            setLogType("claster_min")
        }
    }

    return (
        <div className="claster_container">
            <div className={logType === "claster_max" ? "claster_max" : "claster_min"} >
                <div className="claster_info">
                    <div className="claster_info_text" >
                        <NameSection
                            title={title}
                            all={all}
                            online={online}
                            offline={offline}
                        />
                    </div>
                    <div className="claster_info_button" >
                        <img className="claster_check_img" src="./img/claster-check.svg" alt="" />
                        {checkPermission(permissionsName.devices.clusters.settings) && <img onClick={() => setActive(true)} className="claster_set_img" src="./img/settings.svg" alt="" />}
                        <img onClick={() => logAction()}  className="chevron" src="./img/chevron-down.svg" alt="down" />
                    </div>
                </div>
                <div className="claster_fill" style={{ margin: "20px 40px 0 0" }}>
                    <div className="claster_name_column">
                        <div>OS</div>
                        <div>Friendly Name</div>
                        <div>Devices Name</div>
                        <div>Last Operated</div>
                        <div>Health</div>
                    </div>
                    <div className="claster_fill_log">
                        {devices?.length ? devices.map((entry, key) => {
                            return (
                                <DevicesLog
                                    id={entry.id}
                                    clusterId={id}
                                    inCluster={true}
                                    system={entry.system}
                                    deviceId={entry.id}
                                    key={key}
                                    deviceName={""}
                                    friendlyName={entry.device_name}
                                    connectionStatus={entry.last_operated}
                                    online={entry.online || false}
                                />)
                        }) : <div className="devices_log_cont" style={{ height: "77px", display: "flex", alignItems: "center", justifyContent: "center" }}>There are no devices in this cluster</div>}
                    </div>
                </div>
            </div>
            <SettingsClusterPopup devIncluster={devices} clusterId={id} active={active} setActive={setActive} />
        </div>
    )
})

export default DevicesClaster