const url = "wss://sedot-gate.lineardev.net/shell";
// const url = "ws://192.168.31.66:8000/shell";

class WS {
  remembered: WebSocket | null = null;

  connectWS(): WebSocket {
    if (this.remembered == null) {
      this.remembered = new WebSocket(url);
    }
    return this.remembered;
  }

  async reconnectWS(): Promise<void> {
    if (this.remembered != null) {
      // close the connection
      this.remembered.close();
      // wait for the connection to close
      await new Promise((resolve) => {
        const interval = setInterval(() => {
          if (this.remembered?.readyState == this.remembered?.CLOSED) {
            clearInterval(interval);
            resolve(true);
          }
        }, 100);
      });
      // set the connection to null
      this.remembered = null;
    }
    // create a new connection
    this.remembered = new WebSocket(url);
  }
}

const Web = new WS();
export const ws = (): WebSocket => Web.connectWS()
ws.reconnect = () => Web.reconnectWS()
