import React, { memo, useEffect, useState } from "react";

import "./StatisticsMenu.css"

type PropsType = {
    devices: any[]
}

const StatisticsMenu = memo(({devices}: PropsType) => {

    const [offline, setOffline] = useState<number>(0)

    useEffect(() => {
        if (devices.length) {
            let offline = 0
            devices.forEach((entry) =>{ if (!entry.online) offline++})
            setOffline(offline)
        }
    },[devices])


    return (
        <div className="statistics_menu_cont">
            <div className="statistics_first_cont">
                <div style={{marginRight:"5px"}} className="statistics_inner_cont">
                    <img className="updates_img" src="./img/life-buoy.svg" alt="" />
                    <div className="statistics_inner_text">
                        <div className="statistics_text_title">OS Updates</div>
                        <div className="statistics_text_desc">0 <div className="desc_text_in">of</div> {devices.length}</div>
                    </div>
                </div>
                <div style={{marginLeft:"5px"}} className="statistics_inner_cont">
                    <img className="updates_img" src="./img/life-buoy.svg" alt="" />
                    <div className="statistics_inner_text">
                        <div className="statistics_text_title">THIRD-PARTLY Updates</div>
                        <div className="statistics_text_desc_big">0 <div className="desc_text_in">of</div> {devices.length}</div>
                    </div>
                </div>
            </div>
            <div className="statistics_second_cont">
                <div style={{marginRight:"5px"}} className="statistics_inner_cont">
                    <img className="restart_img" src="./img/reboot.svg" alt="" />
                    <div className="statistics_inner_text">
                        <div className="statistics_text_title">Restart needed </div>
                        <div className="statistics_text_desc">0 <div className="desc_text_in">of</div> {devices.length}</div>
                    </div>
                </div>
                <div  style={{marginLeft:"5px"}} className="statistics_inner_cont">
                    <img className="wifi_off_img" src="./img/wifi-off.svg" alt="" />
                    <div className="statistics_inner_text">
                        <div className="statistics_text_title">Offline</div>
                        <div className="statistics_text_desc">{offline} <div className="desc_text_in">of</div> {devices.length}</div>
                    </div>
                </div>
            </div>
        </div>
    )
})

export default StatisticsMenu