import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { makeFetch } from '../helpers';

type InitialType = {
  id: string
  email: string
  image: string
  name: string;
  inOrgs: [],
  toAuth: boolean,
  isAuth: boolean;
  isLoading: boolean;
  lastWisitedOrg: string;
  permissions: any[]
};

const initialState: InitialType = {
  id: "",
  email: "",
  name: "",
  image: "",
  inOrgs: [],
  toAuth: false,
  isAuth: false,
  isLoading: false,
  lastWisitedOrg:"",
  permissions: []
};

const getUserData = async (t: any, r: any) => {
  const out = () => {
    localStorage.setItem('__account', 'false');
    location.href = '/login';
  };

  if (!t?.length || !r?.length) {
      out();
  } else {
      const url = process.env.REACT_APP_AUTORIZE_URL + '/auth/user';
      const { success, data } = await makeFetch(url, 'GET', null, t);
      if (success) {
          localStorage.setItem('__account', 'true');
          if (!data.lastWisitedOrg.length) {
            location.href = "/freelance";
          }
          return data;
      } else {
          out();
      }
  }
}

export const reloadUser = createAsyncThunk("user/reload", async () => {
  return await getUserData(localStorage.getItem("__t"), localStorage.getItem("__r"))
})

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    addUserData(state, action) {
      state.isAuth = true
      state.id = action.payload.id
      state.email = action.payload.email
      state.name = action.payload.name
      state.image = action.payload.image
      state.inOrgs = action.payload.inOrgs
      state.toAuth = action.payload.toAuth
      state.lastWisitedOrg = action.payload.lastVisitedOrg
    },
    addLastWisited(state, action) {
      state.lastWisitedOrg = action.payload
    },
    logoutUser(state) {
      state.isAuth = false
      state.id = ""
      state.email = ""
      state.name = ""
      state.image = ""
      state.toAuth = false
      state.lastWisitedOrg = ""
    },
    setPermissions(state, action) {
      state.permissions = action.payload
    }
  },

  extraReducers: (builder) => {
    builder.addCase(reloadUser.pending, (state) => {
      state.isLoading = true
    }).addCase(reloadUser.fulfilled, (state, action) => {
      state.isAuth = true
      state.isLoading = false
      state.id = action.payload.id
      state.email = action.payload.email
      state.name = action.payload.name
      state.image = action.payload.image
      state.inOrgs = action.payload.inOrgs
      state.toAuth = action.payload.toAuth
      state.lastWisitedOrg = action.payload.lastWisitedOrg
    })
  }
});

export default userSlice.reducer;
export const { addUserData, logoutUser, addLastWisited, setPermissions } = userSlice.actions