import React, { memo,useState } from 'react';

import KanbanSection from '../KanbanSection/KanbanSection';
import KanbanTask from '../KanbanTask/KanbanTask';

import './KanbanBoard.css';

const KanbanBoard = memo(() => {
    const [elem, setElem] = useState<any[]>([{ title: 'Backlog', tasks: [1,2] },{ title: 'In work', tasks: [3,4] },{ title: 'Done', tasks: [5,6] }]);

    return (
        <div className="kanban_board_main">
            {elem.map((entry, key) => (
                <KanbanSection title={entry.title} key={key}>
                    {entry.tasks.map((el:any, key:any) => <KanbanTask key={key} title={el}/>)}
                </KanbanSection>
            ))}
        </div>
    );
})

export default KanbanBoard;
