import React, { memo, useEffect, useState } from 'react';

import { useAppSelector } from '../../../hooks/redux';
import { permissionsName } from '../../../permissionsData/permissions';
import { makeFetch } from '../../../redux/helpers';
import { fetchClusters } from '../../../redux/reducers/ClustersSlice';
import { fetchDevicesId } from '../../../redux/reducers/DeviceSlice';
import { useAppDispatch } from '../../../redux/store';
import { createModal } from '../../Modals';
import ModalLayout from '../../sectionsFilling/modalLayout/ModalLayout';
import { SearchDropdown } from '../addScriptPopup/AddScriptPopup';

import './AddNewDevicePopup.css';

type PropsType = {
    active: boolean;
    setActive: any;
    clusters: any[];
};

type SelectType = {
    optionsArray: any;
    value: string;
    setValue: any;
    emptyOption: boolean;
};

export const CustomSelect = ({ optionsArray, value, setValue, emptyOption }: SelectType) => {
    return (
        <select
            className="custom_select_area"
            value={value}
            onChange={(e) => {
                setValue(e.target.value);
            }}>
            {emptyOption && <option value=""></option>}
            {optionsArray.map((el: any) => (
                <option value={el} key={el}>
                    {el}
                </option>
            ))}
        </select>
    );
};

const CustomSelectCluster = ({ optionsArray, value, setValue, emptyOption }: SelectType) => {
    return (
        <select
            className="custom_select_area"
            value={value}
            onChange={(e) => {
                setValue(e.target.value);
            }}>
            {emptyOption && <option value=""></option>}
            {optionsArray.map((el: any) => (
                <option value={el.id} key={el.id}>
                    {el.title}
                </option>
            ))}
        </select>
    );
};

export const OsArray = ['Linux', 'Windows', 'macOS', 'Raspberry'];

const AddNewDevicePopup = memo(({ active, setActive, clusters }: PropsType) => {


    const [deviceName, setDeviceName] = useState<string>('');
    const [selectOs, setSelectOs] = useState<string>('Linux');
    const [selectCluster, setSelectCluster] = useState<string>('');

    const [query, setQuery] = useState('');

    const dispatch = useAppDispatch();

    const { lastWisitedOrg: orgId, permissions } = useAppSelector((state) => state.userReducer);

    let fieldsArray = [...OsArray];
    const obj = permissions.find(obj => obj.name === "Add device only with specified OS");
    if (obj) {
        fieldsArray = obj.fields;
    }

    const OsArrToRend = permissions.map((entry) => { if (entry.action[0] === permissionsName.devices.add_only_with_os) { return entry.fields } })

    const [clusterArr, setClusterArr] = useState<any[]>([]);
    useEffect(() => {
        const ClusterArray: any[] = [];
        if (clusters?.length) {
            clusters.map((entry) => {
                ClusterArray.push({ name: entry.name, id: entry.id });
            });
        }
        setClusterArr(ClusterArray);
    }, [clusters]);

    const addNewDeviceFetch = async () => {
        const clusterId = selectCluster ? selectCluster : undefined;
        const reqBody = {
            orgId: orgId,
            device_name: deviceName,
            system: selectOs,
            clusterId: clusterId
        };
        const { success, data } = await makeFetch(
            process.env.REACT_APP_DEVICES_URL + '/devices/add',
            'PUT',
            reqBody,
            String(localStorage.getItem('__t'))
        );
        if (success) {
            console.log(data);
            setActive(false);
            createModal({ error: false, success: true, message: 'Device was succesfully created' });
            setDeviceName('');
            setSelectOs('Linux');
            setSelectCluster('');
            setQuery("")
            dispatch(fetchDevicesId(orgId));
            dispatch(fetchClusters(orgId));
        } else {
            console.log(data);
            createModal({ error: true, message: data.message });
        }
    };

    return (
        <ModalLayout
            active={active}
            setActive={setActive}
            icon={'monitor'}
            sectionName={'Add New Device'}
            maximize={false}
            headColor="blue">
            <div className="add_new_device_popup_container">
                <div>
                    <div className="invite_new_user_inner">
                        <div className="sect_name_text">Device Name:</div>
                        <input
                            className="invite_input"
                            type="text"
                            placeholder="Self-monitoring HySh"
                            value={deviceName}
                            onChange={(e: any) => setDeviceName(e.target.value)}
                        />
                    </div>
                    <div className="invite_new_user_inner">
                        <div className="sect_name_text">Select Device OS:</div>
                        <CustomSelect
                            optionsArray={fieldsArray}
                            value={selectOs}
                            setValue={setSelectOs}
                            emptyOption={false}
                        />
                    </div>
                    <div className="invite_new_user_inner" style={{ marginTop: '20px' }}>
                        <div className="sect_name_text">Assign to a cluster (optional):</div>
                        {/* <CustomSelectCluster
                            optionsArray={clusterArr}
                            value={selectCluster}
                            setValue={setSelectCluster}
                            emptyOption={true}
                        /> */}
                        <SearchDropdown
                            active={active}
                            query={query}
                            setQuery={setQuery}
                            elemArr={clusterArr}
                            setElemId={setSelectCluster}
                        />
                    </div>
                    <div className="save_button_cont">
                        <button
                            disabled={!deviceName}
                            className="save_button"
                            onClick={() => addNewDeviceFetch()}>
                            Save
                        </button>
                    </div>
                </div>
            </div>
        </ModalLayout>
    );
});

export default AddNewDevicePopup;
