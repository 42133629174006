import React, { memo, useMemo } from "react"

import "./Layout.css"

type PropTypes = {
    children?: any
}
const Layout = memo(({children}:PropTypes) => {
    const bgNum = useMemo(() => {return Math.floor(Math.random() * 3)}, [])
    return(
        <>
            <div className="auth-container" >
                <div className="second_section">
                    <>{children}</>
                    <div className="link_container">
                        <button>
                            <img src="/img/authorization/mail.svg" alt="mail" />
                        </button>
                        <button>
                            <img src="/img/authorization/facebook.svg" alt="facebook" />
                        </button>
                        <button>
                            <img src="/img/authorization/linkedin.svg" alt="linkedin" />
                        </button>
                    </div>
                </div>
                <div className="first_section">
                    <img src={`img/background${bgNum}.svg`} alt="background" />
                </div>
            </div>
        </>
    )
})

export default Layout;