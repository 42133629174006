import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';

import Layout from '../../../components/layout/Layout';
import { AuthContentBtn } from '../../signup/signupPage/Signup';

import './LoginOrganization.css';

const LoginOrganization = React.memo(() => {
    const [loading, setLoading] = useState<boolean>(false);
    const userNameRef = useRef<HTMLInputElement>(null);
    const userPassRef = useRef<HTMLInputElement>(null);

    const loginAction = () => {
        console.log('Work!!!');
    };

    return (
        <Layout>
            <div className="section_cont">
                <div className="text_cont">
                    <div className="sign_cont">
                        <h2>Login as Organisation Member</h2>
                        <div>Login to start your work</div>
                    </div>
                </div>
            </div>
            <div>
                <div className="input_container">
                    <div>Organization ID</div>
                    <div className="custom_input">
                        <div className="img_container">
                            <img src="img/authorization/briefcase.svg" alt="" />
                        </div>
                        <input
                            type="email"
                            placeholder="1234567890"
                            onKeyDown={(ev) => {
                                if (ev.key == 'Enter') {
                                    if (userNameRef !== null && userNameRef.current !== null) {
                                        userNameRef.current!.focus();
                                    }
                                }
                            }}
                        />
                    </div>
                </div>
                <div className="input_container">
                    <div>User name</div>
                    <div className="custom_input">
                        <div className="img_container">
                            <img src="img/authorization/user.svg" alt="" />
                        </div>
                        <input
                            ref={userNameRef}
                            type="text"
                            placeholder="youremail@email.com"
                            onKeyDown={(ev) => {
                                if (ev.key == 'Enter') {
                                    if (userPassRef !== null && userPassRef.current !== null) {
                                        userPassRef.current!.focus();
                                    }
                                }
                            }}
                        />
                    </div>
                </div>
                <div className="input_container">
                    <div>Password</div>
                    <div className="custom_input">
                        <div className="img_container">
                            <img src="img/authorization/lock.svg" alt="" />
                        </div>
                        <input
                            ref={userPassRef}
                            type="text"
                            placeholder="********"
                            onKeyDown={(ev) => {
                                if (ev.key == 'Enter') {
                                    if (userPassRef !== null && userPassRef.current !== null) {
                                        loginAction();
                                    }
                                }
                            }}
                        />
                    </div>
                </div>
                <div className="forgot_container">
                    <button>Forgot your password?</button>
                </div>
                <button className="button_login_org" onClick={loginAction}>
                    <AuthContentBtn loading={loading} text={'Login'} />
                </button>
                <div className="signup_button_container">
                    <div className="signup_button_text">
                        Don’t have account?
                        <Link className="signup_link" to="/signup">
                            Sign Up
                        </Link>
                    </div>
                </div>
            </div>
        </Layout>
    );
});

export default LoginOrganization;
