import React, { memo, useEffect } from 'react';

import MainLayout from '../../../components/layout/mainLayout/Layout';

const ScriptsTerminal = memo(() => {

    useEffect(() => {
        document.title = 'SEDOT | Marketplace';
    }, []);
    return (
        <MainLayout>
            <div></div>
        </MainLayout>
    );
});

export default ScriptsTerminal;
