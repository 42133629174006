import React, { ChangeEvent, MouseEvent, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../../../hooks/redux';
import { makeFetch } from '../../../redux/helpers';
import { fetchDevicesId } from '../../../redux/reducers/DeviceSlice';
import { fetchScripts } from '../../../redux/reducers/ScriptsOrgSlice';
import { generateUniqueId } from '../../marketplaceComponent/filter/FiltersComponent';
import { createModal } from '../../Modals';
import ModalLayout from '../../sectionsFilling/modalLayout/ModalLayout';

import './AddScriptPopup.css';

interface SearchResult {
    title: string;
}

type PropsType = {
    active: boolean;
    setActive: any;
};

type SearchType = {
    setElemId: any;
    elemArr: any[];
    query: string;
    setQuery: any;
    active: boolean;
};

export const SearchDropdown = ({ setElemId, elemArr, query, setQuery, active }: SearchType) => {
    const [results, setResults] = useState<any[]>([]);

    const [focus, setFocus] = useState<boolean>(false);
    const search = useCallback(
        (value: any) => {
            setQuery(value);
            if (value.length) {
                const dummyResults: any[] = [];
                // const finder: any[] = [];
                for (let i = 0; i < elemArr.length; i++) {
                    const searchData: string = value.toLowerCase();
                    if (elemArr[i].name.toLowerCase().includes(searchData)) {
                        dummyResults.push({ name: elemArr[i].name, id: elemArr[i].id });
                    }
                }
                if (!value.length) {
                    setResults([]);
                    setQuery('');
                }
                setResults(dummyResults);
                // setFind(finder);
            }
        },
        [query]
    );

    const handleSelect = (result: string) => {
        setQuery(result);
        setElemId(result);
        setFocus(false);
    };

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        // setQuery(e.target.value);
        search(e.target.value);
    };

    const handleClick = (result: string) => {
        handleSelect(result);
    };

    const showedList = useCallback(() => {
        if (focus) {
            if (query) {
                return results.map((result, key) => (
                    <div
                        className="input_element_li"
                        key={generateUniqueId(key)}
                        onClick={() => {
                            handleClick(result.name);
                            setElemId(result.id);
                        }}>
                        {result.name}
                    </div>
                ));
            } else if (!query) {
                console.log(elemArr);
                return elemArr.map((result, key) => (
                    <div
                        className="input_element_li"
                        key={generateUniqueId(key)}
                        onClick={() => {
                            handleClick(result.name);
                            setElemId(result.id);
                        }}>
                        {result.name}
                    </div>
                ));
            }
        } else if (!focus) {
            return <></>;
        }
    }, [focus, query, results, elemArr]);

    useEffect(() => {
        if (!active) {
            setFocus(false);
        }
    }, [active]);

    return (
        <div className="search_select_script_cont ">
            <label htmlFor="selectSearch">
                <img src="/img/select-search-img.svg" alt="" />
            </label>
            <input
                type="text"
                value={query}
                onChange={handleChange}
                onFocus={() => {
                    setFocus(true);
                    console.log('open');
                }}
                // onBlur={() => {
                //     setFocus(false)
                //     console.log("close")
                // }}
                placeholder="Search"
            />
            <div className="search_results" onChange={(e) => e.stopPropagation()}>
                {showedList()}
            </div>
        </div>
    );
};

const AddScriptPopup = ({ active, setActive }: PropsType) => {
    const navigation = useNavigate();

    const [time, setTime] = useState<string>('');
    const [scriptId, setScriptId] = useState<string>('');
    const [schedule, setSchedule] = useState<boolean>(false);
    const [scriptsArr, setScriptsArr] = useState<string[]>([]);

    const [query, setQuery] = useState<string>('');

    const { lastWisitedOrg, email } = useAppSelector((state) => state.userReducer);
    const { scripts } = useAppSelector((state) => state.scriptsOrgSliceReducer);

    const dispatch = useAppDispatch();

    const devId = location.search.split('=')[1];

    const scheduleAction = () => {
        schedule ? setSchedule(false) : setSchedule(true);
    };

    useEffect(() => {
        if (lastWisitedOrg && !scripts.length) {
            dispatch(fetchScripts(lastWisitedOrg));
        }
    }, [lastWisitedOrg]);


    const convertTimeToTimestamp = (time: any) => {
        const timeParts = time.split(' ');
        let milliseconds = 0;

        timeParts.forEach((part: any) => {
            const value = parseInt(part.slice(0, -1));
            const unit = part.slice(-1);

            switch (unit) {
                case 'M':
                    milliseconds += value * 2592000000;
                    break;
                case 'w':
                    milliseconds += value * 604800000;
                    break;
                case 'd':
                    milliseconds += value * 86400000;
                    break;
                case 'h':
                    milliseconds += value * 3600000;
                    break;
                case 'm':
                    milliseconds += value * 60000;
                    break;
                case 's':
                    milliseconds += value * 1000;
                    break;
            }
        });

        return milliseconds;
    }

    useEffect(() => {
        console.log(convertTimeToTimestamp(time));
    }, [time]);

    const addScriptAction = useCallback(async () => {
        const { success, data } = await makeFetch(
            process.env.REACT_APP_DEVICES_URL + '/devices/scripts/add',
            'PUT',
            {
                deviceId: devId,
                scriptId: scriptId,
                scheduled: schedule,
                pattern: schedule ? convertTimeToTimestamp(time) : 0,
                org_id: lastWisitedOrg,
                user: email
            },
            String(localStorage.getItem('__t'))
        );
        if (success) {
            createModal({
                error: false,
                success: true,
                message: 'The script was successfully added to the device'
            });
            setTime('');
            setSchedule(false);
            setScriptId('');
            setQuery('');
            setActive(false);
            dispatch(fetchDevicesId(lastWisitedOrg));
        } else {
            createModal({ error: true, success: true, message: data });
            console.log(data);
        }
    }, [scriptId, schedule, time, devId, lastWisitedOrg, email]);

    const [timeValid, setTimeValid] = useState<boolean>(true);
    useEffect(() => {
        const isValid = /^([1-9][0-9]?[Mwdhms])?(?:\s+[1-9][0-9]?[Mwdhms])*$/.test(time);
        setTimeValid(isValid);
    }, [time]);

    return (
        <ModalLayout
            active={active}
            setActive={setActive}
            icon={'script_plus'}
            sectionName={'Add Script to device'}
            maximize={false}
            headColor="blue">
            <div className="dayreview_add_script_cont">
                {/* <SearchSelect /> */}
                <SearchDropdown
                    active={active}
                    query={query}
                    setQuery={setQuery}
                    elemArr={scripts}
                    setElemId={setScriptId}
                />
                {/* <TestSearch /> */}
                <div style={{ margin: '10px 5px' }} className="custom_radio_cont">
                    <input
                        className="custom-radio"
                        checked={schedule}
                        type="checkbox"
                        id="email"
                        name="email"
                        value="email"
                        onChange={(e) => scheduleAction()}
                    />
                    <label
                        style={{ color: '#158AF0' }}
                        className="input_radio blue_radio_label"
                        htmlFor="email">
                        Schedule
                    </label>
                </div>
                <div className="dayreview_add_script_input" style={{ height: '97px' }}>
                    <input
                        placeholder="Time"
                        type="text"
                        className="input_device_name"
                        value={time}
                        disabled={!schedule}
                        onChange={(e) => {
                            setTime(e.target.value);
                        }}
                        style={!schedule ? { opacity: 0 } : { opacity: 1 }}
                    />
                    {timeValid ? (
                        <div style={{ height: '29px' }}></div>
                    ) : (
                        <div className="valid_time_text">Valid values are: 1M 1w 1d 1h 1m 1s</div>
                    )}
                </div>
                <div className="perm_button_cont">
                    <button
                        disabled={schedule ? !scriptId || !time || !timeValid : !scriptId}
                        className="add_script_button"
                        onClick={addScriptAction}>
                        Add script
                    </button>
                </div>
            </div>
        </ModalLayout>
    );
};

export default AddScriptPopup;
