import React, { memo, useEffect, useMemo, useState } from 'react';
import { Line } from 'react-chartjs-2';
import {
    CategoryScale,
    Chart as ChartJS,
    ChartData,
    ChartDataset,
    ChartOptions,
    Filler,
    Legend,
    LinearScale,
    LineElement,
    PointElement,
    Title,
    Tooltip,
} from 'chart.js';

import { FilledSkeleton } from '../../Loaders/FilledSkeleton';

type PropsType = {
    data: Promise<any[]>
}

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler
);

const options: ChartOptions<"line"> = {
    responsive: true,
    plugins: {
        legend: {
            display: false,
            labels: {
                usePointStyle: true,
                useBorderRadius: false,
            }
        },
    },
    scales: {
        x: {
            border: {
                display: false
            },
            grid: {
                color: (context) => {
                    switch (context.tick.value) {
                        case 0:
                        case 12:
                        case 24:
                        case 38:
                        case 52:
                        case 64:
                        case 78:
                        case 90:
                        case 100:
                            return "#eee";
                        default:
                            return "transparent";
                    }
                }
            }
        },
        y: {
            border: {
                display: false
            },
            grid: {
                color: (context) => {
                    console.log(context)
                    if (context.tick.value == 0) {
                        return "#eee";
                    }

                    if (context.tick.value == 100) {
                        return "#eee";
                    }

                    return "transparent";
                }
            }
        }
    },
    animation: false,
    maintainAspectRatio: false,
};

const labels = [
    "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "",
    "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", 
    "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", 
    "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", 
    "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", 
    "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", 
    "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "",
    "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", 
];

export const HealthChart = ({data}: PropsType) => {

    ChartJS.defaults.scale.max= 100;
    ChartJS.defaults.scale.min = 0;
    ChartJS.defaults.font.size = 14;
    ChartJS.defaults.color = '#485171';

    const [dataArr, setDataArr ] = useState<any[] | null>(null);

    useEffect(() => {
        const load = async () => {
            const tempArr = (await data)?.sort((a, b) => a.timestamp - b.timestamp).map((entry) => {return entry.value})
            // const arrFirstLen = tempArr.length
            // if (arrFirstLen < 7) {
            //     for (let i = 1; i <= 7 - arrFirstLen; i++) tempArr.unshift(0)
            // }
            setDataArr(tempArr);
        }
        load();
    }, [])

    const datasets: ChartDataset<"line", any>[] = useMemo(() => [
        {
            data: dataArr,
            backgroundColor: "rgba(21, 138, 240, 0.2)",
            borderColor: 'rgb(21, 138, 240)',
            borderWidth: 1,
            fill: true,
            pointRadius: 0,
        }

    ], [dataArr])

    const chartData: ChartData<"line", any[], string> = useMemo(() => {return {
        labels,
        datasets,
    }}, [datasets]);


    if (dataArr == null) {
        return (
            <div style={{marginBottom: 20, flex: 1, height: "80%"}}>
                <FilledSkeleton />
            </div>
        )
    }

    return <Line options={options} data={chartData} />;
}

export default HealthChart;