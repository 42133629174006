import React, { useCallback, useEffect, useState } from 'react';

import usePermissions from '../../../helpers/checkPermission';
import { useAppSelector } from '../../../hooks/redux';
import { permissionsName } from '../../../permissionsData/permissions';
import { makeFetch } from '../../../redux/helpers';
import { fetchClusters } from '../../../redux/reducers/ClustersSlice';
import { useAppDispatch } from '../../../redux/store';
import { createModal } from '../../Modals';
import ModalLayout from '../../sectionsFilling/modalLayout/ModalLayout';

type PropsType = {
    active: boolean;
    setActive: any;
    clusterId: string;
    devIncluster: any[];
};

type SelectType = {
    optionsArray: any;
    value: string;
    setValue: any;
    emptyOption: boolean;
};

const CustomSelectDevices = ({ optionsArray, value, setValue, emptyOption }: SelectType) => {
    return (
        <select
            className="custom_select_area"
            value={value}
            onChange={(e) => {
                setValue(e.target.value);
            }}>
            {emptyOption && <option value=""></option>}
            {optionsArray.map((el: any, key: any) => (
                <option value={el.id} key={key}>
                    {el.title}
                </option>
            ))}
        </select>
    );
};

const SettingsClusterPopup = ({ clusterId, active, setActive, devIncluster }: PropsType) => {

    const { checkPermission } = usePermissions()

    const [devArr, setDevArr] = useState<any[]>([]);
    const [devVal, setDevVal] = useState<any>();

    const { devices } = useAppSelector((store) => store.deviceReducer);
    const { lastWisitedOrg } = useAppSelector((store) => store.userReducer);

    const dispatch = useAppDispatch();

    const setArrSelect = useCallback(() => {
        const devTempArr: any[] = [];

        if (devices.length) {
            devices.forEach((entry) => {
                const isElementInCluster = devIncluster.some((element) => element.id === entry.id);
                if (!isElementInCluster) {
                    devTempArr.push({ title: entry.device_name, id: entry.id });
                }
            });
            setDevArr(devTempArr);
        }
    }, [devices, devIncluster]);

    useEffect(() => {
        setArrSelect();
    }, [devices, devIncluster]);

    const deleteClusterAction = useCallback(async () => {
        const { success, data } = await makeFetch(
            process.env.REACT_APP_ORG_URL + '/org/cluster/remove',
            'DELETE',
            { orgId: lastWisitedOrg, clusterId: clusterId },
            String(localStorage.getItem("__t"))
        );
        if (success) {
            console.log(data);
            createModal({
                error: false,
                success: true,
                message: 'Cluster was succesfully removed'
            });
            setActive(false);
            dispatch(fetchClusters(lastWisitedOrg));
        } else {
            console.log(data);
            createModal({ error: true, message: data.message });
        }
    }, [clusterId]);

    const addDeviceToCluster = useCallback(async () => {
        console.log(clusterId);
        const { success, data } = await makeFetch(
            process.env.REACT_APP_DEVICES_URL + '/cluster/device/add',
            'POST',
            {
                clusterId: clusterId,
                deviceId: devVal,
                org_id: lastWisitedOrg
            },
            String(localStorage.getItem("__t"))
        );
        if (success) {
            console.log(data);
            createModal({
                error: false,
                success: true,
                message: 'Cluster was succesfully created'
            });
            setActive(false);
            dispatch(fetchClusters(lastWisitedOrg));
        } else {
            console.log(data);
            createModal({ error: true, message: data.message });
        }
    }, [clusterId, devVal, lastWisitedOrg]);

    return (
        <ModalLayout
            active={active}
            setActive={setActive}
            icon={'settings'}
            sectionName={'Cluster settings'}
            maximize={false}
            headColor="blue">
            <div className="create_cluster_popup_cont">
                <div className="sect_name_text" style={{ marginBottom: '15px' }}>
                    Add device to cluster
                </div>
                <div className="sect_name_text">Select Device: </div>
                <CustomSelectDevices
                    optionsArray={devArr}
                    value={devVal}
                    setValue={setDevVal}
                    emptyOption={true}
                />
                <div className="save_button_cont">
                    {checkPermission(permissionsName.devices.clusters.remove) && <button
                        className="control_delete_button"
                        style={{ margin: '0 10px 0 0' }}
                        onClick={() => deleteClusterAction()}>
                        Delete cluster
                    </button>}
                    {checkPermission(permissionsName.devices.clusters.assign_device) && <button
                        disabled={!devVal}
                        className="create_cluster_button"
                        onClick={() => addDeviceToCluster()}>
                        Add device
                    </button>}
                </div>
            </div>
        </ModalLayout>
    );
};

export default SettingsClusterPopup;
