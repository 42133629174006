import React from "react";
import { memo } from "react";
import { Link } from "react-router-dom";

const Page404 = memo(() => {
    return (
        <div style={{ display: "flex",width:"100vw",height:"100vh",alignItems: "center", justifyContent: "center" }}>
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                <h1 style={{margin:"0 auto",fontSize:"60px"}}>404</h1>
                <h2 style={{margin:"0 auto"}}> Page not found</h2>
                <Link to="/" style={{marginTop:"10px",fontSize:"20px"}}>Go to main page</Link>
            </div>

        </div>
    )
})

export default Page404