import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { createBrowserHistory } from 'history';

import { store } from './redux/store';
import { Navigation } from './navigation';

import './index.css';

const app: HTMLElement = document.getElementById('root') as HTMLElement
const root = ReactDOM.createRoot(app);

const history = createBrowserHistory() 

if (app) {
  const path = (/#!(\/.*)$/.exec(window.location.hash) || [])[1];
    if (path) {
        history.replace(path);
    }

  root.render(
    <Provider store={store}>
      <BrowserRouter>
        <Navigation />
      </BrowserRouter>
    </Provider>
);
}
