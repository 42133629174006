// export const serviceLogin = async () => {
//     const data = create(String(process.env.REACT_APP_USER_DEFAULT) + String(process.env.REACT_APP_PASS_DEFAULT))
//     const value = {
//         data: data.key,
//         timestamp: data.timestamp
//     }
//     console.log(value)
//     const url = process.env.REACT_APP_AUTORIZE_URL+"/auth/mlogin"
//     const login = await fetch(url, {
//         method: "POST",
//         headers: {
//             "Content-Type": "application/json"
//         },
//         body: JSON.stringify(value)
//     })
//     if (!login.ok) {
//         return console.error("error while login in reg service")
//     }
// }

// export const getAuthToken = async (): Promise<string> => {
//     const d = create(String(process.env.REACT_APP_USER_DEFAULT) + String(process.env.REACT_APP_PASS_DEFAULT))
//     const value = {
//         data: d.key,
//         timestamp: d.timestamp
//     }
//     const url = process.env.REACT_APP_AUTORIZE_URL+"/auth/token"
//     const login = await fetch(url, {
//         method: "POST",
//         headers: {
//             "Content-Type": "application/json"
//         },
//         body: JSON.stringify(value)
//     })
//     if (!login.ok) {
//         console.error("error while login in reg service")
//         return ""
//     }
//     const data = await login.text()
//     console.log(data)
//     return data
// }
type Response = {
    success: boolean
    data: any
}

export const makeFetch = async (
    url: string,
    method: string, 
    value: object | null,
    auth?: string
): Promise<Response> => {
    const d = value !== null ? {method, headers: { "Content-Type": "application/json", "Authorization": `Bearer ${auth}` }, body: JSON.stringify(value)} : { method, headers: { "Content-Type": "application/json", "Authorization": `Bearer ${auth}` }}
    const req = await fetch(url, d)
    if (!req.ok) {
        const data = await req.json()
        
        if (data.message !== "Token expired") {
            console.error(data)
            return {success: false, data: data.message}
        } else {
            const {success, data:newTokenGroup} = await makeFetch(process.env.REACT_APP_AUTORIZE_URL+"/auth/refresh", "GET", null, String(localStorage.getItem("__r")))
            if (!success) {
                return {success: false, data: newTokenGroup}
            }
            console.log(newTokenGroup)
            localStorage.setItem("__t", newTokenGroup.token)
            localStorage.setItem("__r", newTokenGroup.refresh)
            const newData = await makeFetch(url, method, value, newTokenGroup.token);
            return newData
        }
    }

    let data: any = null;

    if (req.headers.get("Content-Type")?.includes("application/json")) {
        data = await req.json()
    } else {
        data = await req.text()
    }

    if (data["status"] != undefined) {
        if (!data.status) {
            console.error(data.message)
            return {success: false, data: data}
        }
    }
    return {success: true, data: data};
}