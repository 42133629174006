import React, { ChangeEvent, Dispatch, memo, SetStateAction, useCallback, useRef } from 'react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import Layout from '../../../components/layout/Layout';
import { createModal } from '../../../components/Modals';
import { makeFetch } from '../../../redux/helpers';
import { addLastWisited, addUserData } from '../../../redux/reducers/UserSlice';

import './Signup.css';

const SignUpHeader = memo(({ stateEvent }: { stateEvent: number }) => (
    <>
        {stateEvent === 1 ? (
            <div className="section_cont">
                <div className="text_cont">
                    <div className="sign_cont">
                        <h2>Sign Up</h2>
                        <div>Sign up to create new account</div>
                    </div>
                    <div>1/3</div>
                </div>
            </div>
        ) : stateEvent === 2 ? (
            <div className="section_cont">
                <div className="text_cont">
                    <div className="sign_cont">
                        <h2>Sign Up</h2>
                        <div>Email confirmation</div>
                    </div>
                    <div>2/3</div>
                </div>
            </div>
        ) : stateEvent === 3 ? (
            <div className="section_cont">
                <div className="text_cont">
                    <div className="sign_cont">
                        <h2>Sign Up</h2>
                        <div>Account information</div>
                    </div>
                    <div>3/3</div>
                </div>
            </div>
        ) : stateEvent === 4 ? (
            <div className="section_cont">
                <div className="text_cont">
                    <div className="sign_cont">
                        <h2>Create Organization</h2>
                    </div>
                </div>
            </div>
        ) : stateEvent === 5 ? (
            <div className="section_cont">
                <div className="text_cont">
                    <div className="sign_cont">
                        <h2>Registered successfully</h2>
                    </div>
                </div>
            </div>
        ) : (
            <div>Error</div>
        )}
    </>
));

type signUpFirstProps = {
    loading: boolean;
    setEmail: Dispatch<SetStateAction<string>>;
    setName: Dispatch<SetStateAction<string>>;
    regFirstStep: () => void;
};

export const AuthContentBtn = memo(({ loading, text }: { loading: boolean; text: string }) => (
    <>
        {!loading ? (
            text
        ) : (
            <img className="small-loader-white" src="/img/loader-white.svg" alt="loading" />
        )}
    </>
));

const SignUpFirst = memo(({ loading, setEmail, setName, regFirstStep }: signUpFirstProps) => {
    const emailHandler = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        setEmail(e.target.value);
    }, []);
    const nameRef = useRef<HTMLInputElement>(null);
    return (
        <>
            <div id="form">
                <div style={{ marginTop: 16 }} className="input_container">
                    <div className="input_head">Email</div>
                    <div className="custom_input">
                        <div className="img_container">
                            <img src="/img/authorization/mailInput.svg" alt="" />
                        </div>
                        <input
                            key={1}
                            type="email"
                            placeholder="youremail@email.com"
                            onChange={(e) => {
                                emailHandler(e);
                            }}
                            onKeyDown={(ev) => {
                                if (ev.key == 'Enter') {
                                    if (nameRef !== null && nameRef.current !== null) {
                                        nameRef.current!.focus();
                                    }
                                }
                            }}
                        />
                    </div>
                </div>
                <div style={{ marginBottom: 50 }} className="input_container">
                    <div className="input_head">Full Name</div>
                    <div className="custom_input">
                        <div className="img_container">
                            <img src="/img/authorization/user.svg" alt="" />
                        </div>
                        <input
                            ref={nameRef}
                            key={2}
                            type="name"
                            placeholder="Jhon Smith"
                            onChange={(e) => {
                                setName(e.target.value);
                            }}
                            onKeyDown={(ev) => {
                                if (ev.key == 'Enter') {
                                    if (nameRef !== null && nameRef.current !== null) {
                                        regFirstStep();
                                    }
                                }
                            }}
                        />
                    </div>
                </div>
                <div className="auth-btn-group">
                    <button onClick={regFirstStep}>
                        <AuthContentBtn loading={loading} text={'Sing Up'} />
                    </button>
                </div>
            </div>
            <SignUpFooter />
        </>
    );
});

const SignUpFooter = memo(() => (
    <div className="signup_button_container">
        <div className="signup_button_text">
            Do you already have the account?
            <Link style={{ marginLeft: 4 }} className="signup_link" to="/login">
                Sign In
            </Link>
        </div>
    </div>
));

const Signup = memo(() => {
    const navigation = useNavigate();
    const dispatch = useDispatch();

    const [loading, setLoading] = useState<boolean>(false);
    const [stateEvent, setStateEvent] = useState<number>(1);
    const [email, setEmail] = useState<string>('');
    const [org, setOrg] = useState<string>('');
    const [otp, setOTP] = useState<string>('');
    const [name, setName] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [passwordTwo, setPasswordTwo] = useState('');

    const acceptInvitation = useCallback(async () => {
        const invitationId = window.location.search.split('=')[1];
        const { data } = await makeFetch(
            process.env.REACT_APP_ORG_URL + `/org/invitation/exist?id=${invitationId}`,
            'GET',
            null,
            String(localStorage.getItem('__t'))
        );
        const { success } = await makeFetch(
            process.env.REACT_APP_ORG_URL +
                `/org/invitation/accept?id=${window.location.search.split('=')[1]}`,
            'GET',
            null,
            String(localStorage.getItem('__t'))
        );

        if (success) {
            const { success: modified } = await makeFetch(
                process.env.REACT_APP_AUTORIZE_URL + '/auth/lastwisited',
                'POST',
                { orgId: data.orgId },
                String(localStorage.getItem('__t'))
            );

            if (modified) {
                dispatch(addLastWisited(data.orgId));
                navigation('/');
            }
        }
    }, []);

    const regFirstStep = useCallback(async () => {
        if (!loading) {
            setLoading(true);
            const url = process.env.REACT_APP_AUTORIZE_URL + '/auth/registration/one';
            if (!email.length && !email.includes('@') && !email.includes('.')) {
                setLoading(false);
                return createModal({ error: false, message: 'Email is not correct' });
            }

            if (name.length < 3) {
                setLoading(false);
                return createModal({ error: false, message: 'The name is too short' });
            }

            const value = {
                email: email,
                name: name
            };
            const { success, data } = await makeFetch(url, 'PUT', value);
            setLoading(false);
            if (success) {
                createModal({ error: false, success: true, message: String(data.message) });
                setStateEvent(stateEvent + 1);
            } else {
                createModal({ error: true, message: String(data.message) });
            }
        }
    }, [loading, email, name]);

    const regSecStep = useCallback(async () => {
        if (!loading) {
            if (otp.length !== 5) {
                return createModal({ error: false, message: 'OTP code must consist of 5 digits' });
            }
            setLoading(true);
            const url = process.env.REACT_APP_AUTORIZE_URL + '/auth/registration/otp';
            const value = {
                email: email,
                otp: otp
            };
            const { success, data } = await makeFetch(url, 'PUT', value);
            setLoading(false);
            if (success) {
                createModal({ error: false, success: true, message: 'OTP Correct' });
                setStateEvent(stateEvent + 1);
            } else {
                createModal({ error: true, message: data.message });
            }
        }
    }, [loading, otp]);

    const regSecThree = useCallback(async () => {
        if (!loading) {
            if (password !== passwordTwo) {
                return createModal({ error: false, message: "The passwords don't match" });
            }
            setLoading(true);
            const url = process.env.REACT_APP_AUTORIZE_URL + '/auth/registration/three';
            const value = {
                email: email,
                password: password
            };
            const { success, data } = await makeFetch(url, 'PUT', value);
            setLoading(false);
            //TODO: Create pop up with error
            if (success) {
                createModal({
                    error: false,
                    success: true,
                    message: 'You have successfully registered'
                });
                localStorage.setItem('__account', 'true');
                localStorage.setItem('__t', data.token);
                localStorage.setItem('__r', data.refresh);
                dispatch(addUserData(data));
                if (window.location.search.includes('invitation')) {
                    setStateEvent(stateEvent + 2);
                    acceptInvitation();
                } else {
                    setStateEvent(stateEvent + 1);
                }
            } else {
                createModal({ error: true, message: data.message });
            }
        }
    }, [loading, password, passwordTwo]);

    const goToHome = useCallback(() => {
        const loader = document.getElementById('loader-overflow') as HTMLElement;
        loader.style.display = 'block';
        navigation('/');
        setTimeout(() => {
            loader.style.display = 'none';
        }, 500);
    }, []);

    const regSecFour = useCallback(async () => {
        if (!loading) {
            if (!org.length) {
                return createModal({
                    error: false,
                    message: 'Please fill in the "Organization Name" field'
                });
            }
            setLoading(true);
            const url = process.env.REACT_APP_ORG_URL + '/org/create';
            const { success, data } = await makeFetch(
                url,
                'PUT',
                {
                    name: org
                },
                String(localStorage.getItem('__t'))
            );
            setLoading(false);
            if (success) {
                createModal({
                    error: false,
                    success: true,
                    message: 'You have successfully created a new organization'
                });
                goToHome();
            } else {
                createModal({ error: true, message: 'Creation error' });
            }
            dispatch(addLastWisited(data?.message));
        }
    }, [loading, org]);

    const confirmPassRef = useRef<HTMLInputElement>(null);
    // const emailRef = useRef<HTMLInputElement>(null);

    return (
        <Layout>
            <SignUpHeader stateEvent={stateEvent} />
            {stateEvent === 1 ? (
                <SignUpFirst
                    loading={loading}
                    setEmail={setEmail}
                    setName={setName}
                    regFirstStep={regFirstStep}
                />
            ) : stateEvent === 2 ? (
                <>
                    <div id="form">
                        <div className="info_container">
                            <div className="info_container-text">
                                We send an email with a verification code to <b>{email}</b>. (
                                <span className="not_you">not you?</span>)
                            </div>
                            <div style={{ marginTop: 10 }}>
                                Enter it below to confirm your email
                            </div>
                        </div>
                        <div
                            style={{ marginBottom: 50, marginTop: 48 }}
                            className="input_container">
                            <div className="input_head">Verification code</div>
                            <div className="custom_input">
                                <div className="img_container">
                                    <img src="/img/authorization/lock.svg" alt="" />
                                </div>
                                <input
                                    key={3}
                                    type="text"
                                    placeholder="01234"
                                    onChange={(e) => {
                                        setOTP(e.target.value);
                                    }}
                                    onKeyDown={(ev) => {
                                        if (ev.key == 'Enter') {
                                            regSecStep();
                                        }
                                    }}
                                />
                            </div>
                        </div>
                        <div className="auth-btn-group">
                            <button onClick={() => regSecStep()}>
                                <AuthContentBtn loading={loading} text={'Next'} />
                            </button>
                        </div>
                    </div>
                    <SignUpFooter />
                </>
            ) : stateEvent === 3 ? (
                <>
                    <div id="form">
                        <div style={{ marginTop: 16 }} className="input_container">
                            <div className="input_head">Password</div>
                            <div className="custom_input">
                                <div className="img_container">
                                    <img src="/img/authorization/lock.svg" alt="" />
                                </div>
                                <input
                                    key={4}
                                    onChange={(e) => {
                                        setPassword(e.target.value);
                                    }}
                                    type="password"
                                    placeholder="********"
                                    onKeyDown={(ev) => {
                                        if (ev.key == 'Enter') {
                                            if (
                                                confirmPassRef !== null &&
                                                confirmPassRef.current !== null
                                            ) {
                                                confirmPassRef.current!.focus();
                                            }
                                        }
                                    }}
                                />
                            </div>
                        </div>
                        <div style={{ marginBottom: 50 }} className="input_container">
                            <div className="input_head">Confirm Password</div>
                            <div className="custom_input">
                                <div className="img_container">
                                    <img src="/img/authorization/lock.svg" alt="" />
                                </div>
                                <input
                                    ref={confirmPassRef}
                                    key={5}
                                    onChange={(e) => {
                                        setPasswordTwo(e.target.value);
                                    }}
                                    type="password"
                                    placeholder="********"
                                    onKeyDown={(ev) => {
                                        if (ev.key == 'Enter') {
                                            if (
                                                confirmPassRef !== null &&
                                                confirmPassRef.current !== null
                                            ) {
                                                regSecThree();
                                            }
                                        }
                                    }}
                                />
                            </div>
                        </div>
                        <div className="auth-btn-group">
                            <button onClick={regSecThree}>
                                <AuthContentBtn loading={loading} text={'Next'} />
                            </button>
                        </div>
                    </div>
                    <SignUpFooter />
                </>
            ) : stateEvent === 4 ? (
                <>
                    <div id="form">
                        <div style={{ marginBottom: 50, marginTop: 1 }} className="info_container">
                            <div>
                                If you need, we can help you build your organization. Enter a name
                                below or skip this step
                            </div>
                        </div>
                        <div style={{ marginBottom: 38 }} className="input_container">
                            <div className="input_head">Organization Name</div>
                            <div className="custom_input">
                                <div className="img_container">
                                    <img src="/img/authorization/briefcase.svg" alt="" />
                                </div>
                                <input
                                    key={6}
                                    type="text"
                                    placeholder="My Example Corp"
                                    onChange={(e) => {
                                        setOrg(e.target.value);
                                    }}
                                    onKeyDown={(ev) => {
                                        if (ev.key == 'Enter') {
                                          if(org === "") {
                                            goToHome()
                                          } else {
                                            regSecFour();
                                          }
                                        }
                                    }}
                                />
                            </div>
                        </div>
                        <div className="auth-btn-group">
                            <button
                                style={{
                                    backgroundColor: '#F8F8F8',
                                    color: '#485171',
                                    marginBottom: 10
                                }}
                                onClick={goToHome}>
                                Skip
                            </button>
                            <button onClick={() => regSecFour()}>
                                <AuthContentBtn loading={loading} text={'Create'} />
                            </button>
                        </div>
                    </div>
                    <SignUpFooter />
                </>
            ) : stateEvent == 5 ? (
                <>
                    <div id="form">
                        <div className="invitation_info_container">
                            <img src="/img/authorization/succesfull_green.svg" alt="" />
                            <span>Invitation Confirmed</span>
                        </div>
                    </div>
                </>
            ) : (
                <div>error</div>
            )}
        </Layout>
    );
});

export default Signup;
