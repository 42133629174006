import React, {memo,useState} from 'react';

const SedotTaskButton = memo(() => {

  const [active,setActive] = useState<boolean>(false)

    return (
        <>
             <button className="add_devices_button_cont" onClick={() => setActive(true)}>
                <img className="devices_button_img" src="/img/tasks/sedot_task.svg" alt="" />
                <div style={{marginLeft:"15px"}} className="add_devices_text">SEDOT Task</div>
            </button>
        </>
    );
});

export default SedotTaskButton;
