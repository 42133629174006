export function timeAgo(timestamp: number) {
    const seconds = Math.floor((Date.now() - timestamp) / 1000);
    const intervals = {
        'year': 31536000,
        'month': 2592000,
        'week': 604800,
        'day': 86400,
        'hour': 3600,
        'minute': 60,
        'second': 1
    };

    for (const [unit, secondsInterval] of Object.entries(intervals)) {
        const interval = Math.floor(seconds / secondsInterval);
        if (interval >= 1) {
            return interval + ' ' + unit + (interval === 1 ? ' ago' : 's ago');
        }
    }
    return 'Just now';
}