import React, { useState } from "react";
import { memo } from "react";

import "./Progressbar.css";


const Progressbar = memo(() => {

    const [stageOne, setStageOne] = useState<boolean>(true)
    const [stageTwo, setStageTwo] = useState<boolean>(false)
    const [stageThree, setStageThree] = useState<boolean>(false)


    return (
        <div className="progress_container">
            <div style={{display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center" ,minWidth:"303px"}}>
                <svg width="121" height="143" viewBox="0 0 121 143" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ marginBottom: "-35px"}}>
                    <path
                        d="M60.5 141.904L119.829 103.34L60.5 0.996951L1.17086 103.34L60.5 141.904Z"
                        fill={!stageTwo ? "rgba(4, 202, 126, 0.5)" : "#04CA7E"}
                        stroke={!stageTwo ? "#04CA7E" : "#00935A"}
                    />
                </svg>
                <div style={{display:"flex", flexDirection:"row"}}>
                    <svg width="119" height="103" viewBox="0 0 119 103" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ marginRight: "5px" }}>
                        <path
                            d="M118.5 39.2713V102.5H0.862626L59.171 0.707473L118.5 39.2713Z"
                            fill={!stageOne ? "rgba(4, 202, 126, 0.5)" : "#04CA7E"}
                            stroke={!stageOne ? "#04CA7E" : "#00935A"}
                        />
                    </svg>

                    <svg width="119" height="103" viewBox="0 0 119 103" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M60 0L0 39V103H119L60 0Z"
                            fill={!stageThree ? "rgba(4, 202, 126, 0.5)" : "#04CA7E"}
                            stroke={!stageThree ? "#04CA7E" : "#00935A"}
                        />
                    </svg>
                </div>
            </div>
            <div className="progress_title">You&apos;re almost done!</div>
            <div className="progress_tips_container">
                <div className="progress_tasks_container" >
                    {stageOne
                        ?
                        <img src="./img/tips_done.svg" alt="" />
                        :
                        <img src="./img/tips.svg" alt="" />
                    }

                    <div className="progress_tasks_text">Add devices to be monitored and managed</div>
                </div>
                <div className="progress_tasks_container">
                    {stageTwo
                        ?
                        <img src="./img/tips_done.svg" alt="" />
                        :
                        <img src="./img/tips.svg" alt="" />
                    }
                    <div className="progress_tasks_text">Add users to your organization</div>
                </div>
                <div className="progress_tasks_container">
                    {stageThree
                        ?
                        <img src="./img/tips_done.svg" alt="" />
                        :
                        <img src="./img/tips.svg" alt="" />
                    }
                    <div className="progress_tasks_text">From these users, form teams for particular tasks</div>
                </div>
            </div>
        </div>
    )
})

export default Progressbar