import React, { memo, useState } from 'react';

import AddScriptPopup from '../addScriptPopup/AddScriptPopup';

import './AddScriptButton.css';

type PropsType = {
    refreshData: any
}

const AddScriptButton = memo(({refreshData} : PropsType) => {
    const [active, setActive] = useState<boolean>(false);
    return (
        <>
            <div className="add_script_button_cont" onClick={() => setActive(true)}>
                <img src="/img/file-minus.svg" alt="" />
                <div>Add Script to Marketplace</div>
            </div>
            <AddScriptPopup refreshData={refreshData} active={active} setActive={setActive} />
        </>
    );
});

export default AddScriptButton;
