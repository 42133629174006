import React, { lazy, memo, Suspense, useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import usePermissions from '../../../helpers/checkPermission';
import { timeAgo } from '../../../helpers/time';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux';
import { permissionsName } from '../../../permissionsData/permissions';
import { makeFetch } from '../../../redux/helpers';
import { fetchClusters } from '../../../redux/reducers/ClustersSlice';
import { fetchDevicesId, systemTypes } from '../../../redux/reducers/DeviceSlice';
import { createModal } from '../../Modals';
import HealthChart from '../devicesChart/HealthChart';

import './DevicesLog.css';

const SystemImg = memo(({ system }: { system: systemTypes }) => {
    switch (system) {
        case 'Windows':
            return <img className="devices_log_img" src='./img/windows.svg' alt="logo" />;
        case 'macOS':
            return <img className="devices_log_img" src='./img/macOS.svg' alt="logo" />;
        case 'Linux':
            return <img className="devices_log_img" style={{ margin: "0 0 0 15px" }} src='./img/linux.svg' alt="logo" />;
        case 'Raspberry':
            return <img className="devices_log_img" style={{ width: "32px", height: "37px", margin: "0 0 0 23px" }} src='./img/raspberry.svg' alt="logo" />;
    }
});

const TimeLastOperated = memo(
    ({ connectionStatus, online }: { connectionStatus: number | null, online: boolean }) => {
        if (!connectionStatus) {
            return online ? <div className="log_ago">never</div> : <div className="log_no_connect">never</div>
        }
        if (connectionStatus === null) {
            return online ? <div className="log_ago">never</div> : <div className="log_no_connect">never</div>
        }

        const timeNow = new Date().getTime()
        const timeOperated = new Date(connectionStatus).getTime()
        const differenceMs = timeNow - timeOperated;
        const seconds = Math.floor(differenceMs / 1000);
        const minutes = Math.floor(seconds / 60);
        const hours = Math.floor(minutes / 60);
        const days = Math.floor(hours / 24);

        if (minutes <= 0) {
            return <div key={"seconds_time"} className={online ? "log_ago" : "log_no_connect"}>{seconds} seconds ago</div>;
        } else if (hours <= 0) {
            return <div key={"minutes_time"} className={online ? "log_ago" : "log_no_connect"}>{minutes} minutes ago</div>;
        } else if (days <= 0) {
            return <div key={"hours_time"} className={online ? "log_ago" : "log_no_connect"}>{hours} hours ago</div>;
        } else {
            return <div key={"days_time"} className={online ? "log_ago" : "log_no_connect"}>{days} days ago</div>;
        }
    }
)

type PropsType = {
    id: string;
    system: systemTypes;
    connectionStatus: null | number;
    friendlyName: string;
    deviceName: string;
    lastOperated?: string;
    health?: string;
    deviceId: string;
    inCluster?: boolean;
    clusterId?: string;
    removeDev?: boolean;
    online: boolean
};

const DevicesLog = memo(
    ({
        id,
        connectionStatus,
        friendlyName,
        deviceName,
        lastOperated,
        health,
        deviceId,
        system,
        inCluster,
        clusterId,
        removeDev,
        online
    }: PropsType) => {
        const navigate = useNavigate();
        const dispatch = useAppDispatch();
        const { checkPermission } = usePermissions()

        const { lastWisitedOrg } = useAppSelector((store) => store.userReducer)

        const [check, setCheck] = useState(false);
        // const [cpuArrPerWeek, setCpuArrPerWeek] = useState<any[]>([]);

        const navigateTo = () => {
            navigate(`/devices/dayreview?id=${deviceId}`);
        };

        const removeFromCluster = useCallback(async () => {
            const { success, data } = await makeFetch(
                process.env.REACT_APP_DEVICES_URL + '/cluster/device/remove',
                'DELETE',
                {
                    clusterId: clusterId,
                    deviceId: deviceId,
                    org_id: lastWisitedOrg
                },
                String(localStorage.getItem("__t"))
            );
            if (success) {
                console.log(data);
                createModal({
                    error: false,
                    success: true,
                    message: 'Device was succesfully remove from cluster'
                });
                dispatch(fetchClusters(lastWisitedOrg));
            } else {
                console.log(data);
                createModal({
                    error: true,
                    success: true,
                    message: data
                });
            }
        }, [clusterId, deviceId, lastWisitedOrg]);

        const removeDevice = useCallback(async () => {
            const { success, data } = await makeFetch(
                process.env.REACT_APP_DEVICES_URL + `/devices/remove/${deviceId}/${lastWisitedOrg}`,
                'DELETE',
                null,
                String(localStorage.getItem("__t"))
            );
            if (success) {
                console.log(data);
                createModal({
                    error: false,
                    success: true,
                    message: 'Device was succesfully remove'
                });
                dispatch(fetchDevicesId(lastWisitedOrg));
            } else {
                console.log(data);
                createModal({
                    error: true,
                    success: true,
                    message: data
                });
            }
        }, [deviceId, lastWisitedOrg]);

        const cpuArrPerWeek = useMemo(async () => {
            const { success, data } = await makeFetch(
                `${process.env.REACT_APP_DEVICES_URL}/stats/load`,
                'POST',
                {
                    device: id,
                    org_id: lastWisitedOrg
                },
                String(localStorage.getItem('__t'))
            );
            if (success) {
                console.log(data);
                return data;
            } else {
                return [];
            }
        }, [id,lastWisitedOrg]);

        return (
            <div className="devices_log_cont">
                <div className="devices_log_cont_first" onClick={() => navigateTo()}>
                    <SystemImg system={system} />
                    <div>{friendlyName}</div>
                    {online ? (
                        <div className="status_online">Online</div>
                    ) : (
                        <div className="status_offline"> Offline</div>
                    )}
                    <TimeLastOperated connectionStatus={connectionStatus} online={online} />
                    <div className="log_health">
                        {checkPermission(permissionsName.device.view_health_chart) &&
                            <Suspense>
                                <HealthChart data={cpuArrPerWeek} />
                            </Suspense>
                        }
                    </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    {check ? (
                        <img
                            className="claster_check_img"
                            src="/img/tips_done.svg"    
                            alt=""
                            onClick={() => setCheck(!check)}
                        />
                    ) : (
                        <img
                            style={{ marginRight: '22px' }}
                            className="claster_check_img"
                            src="/img/claster-check.svg"
                            alt=""
                            onClick={() => setCheck(!check)}
                        />
                    )}

                    {inCluster ? checkPermission(permissionsName.devices.clusters.remove_device) && (
                        <img
                            style={{ marginRight: '10px', cursor: 'pointer' }}
                            src="/img/trash-red.svg"
                            onClick={removeFromCluster}
                        />
                    ) : (
                        <></>
                    )}
                    {removeDev ? checkPermission(permissionsName.devices.remove) && (
                        <img
                            style={{ marginRight: '10px', cursor: 'pointer' }}
                            src="/img/trash-red.svg"
                            onClick={removeDevice}
                        />
                    ) : (
                        <></>
                    )}
                </div>
            </div>
        );
    }
);

export default DevicesLog;
