import React, {memo} from 'react';

import './KanbanSection.css';

type PropsType = {
    title: string;
    children: any
};

const KanbanSection = memo(({ title,children }: PropsType) => {
    return (
        <div className="kanban_section_cont">
            <div className='kanban_section_header'>
                {title}
            </div>
            <div>
                {children}
            </div>
        </div>
    );
});

export default KanbanSection;
