import React, { memo } from 'react';

import './NameSection.css';

type propsSect = {
    title: string;
    subtitle?: string;
    all?: number;
    online?: number;
    offline?: number;
    other?: string;
    creationTime?: string;
    expirationTime?: string;
};

const NameSection = memo(
    ({ title, subtitle, all, online, offline, other, creationTime, expirationTime }: propsSect) => {
        return (
            <div className="name_section_container">
                <h2>{title} 
                    {/* {expirationTime && <span className='temporary_shevron'>temporary</span>}  */}
                </h2>
                <h3>{subtitle}</h3>
                {!all && all !== 0 ? (
                    <></>
                ) : (
                    <>
                        <h3>All : {all}</h3>
                    </>
                )}
                {!online ? (
                    <></>
                ) : (
                    <>
                        <h3>Online : {online}</h3>
                    </>
                )}
                {!offline ? (
                    <></>
                ) : (
                    <>
                        <h3>Offline : {offline}</h3>
                    </>
                )}
                {!other && !creationTime ? (
                    <></>
                ) : (
                    <>
                        <h3>
                            <span>{other}</span>
                            <span style={{ margin: '0 5px' }}>|</span>
                            <span>Creation Time: {creationTime}</span>

                            {expirationTime && (
                                <span>
                                    <span style={{ margin: '0 10px 0 10px' }}>|</span>
                                    Expiration Time : {expirationTime.replace('T', ' ').replace('Z', '')}
                                </span>
                            )}
                        </h3>
                    </>
                )}
            </div>
        );
    }
);

export default NameSection;
