import React, { memo, useState } from "react";

import "./LogComponent.css"

export type logsType = {
    id: string
    title: string
    time: number
    timeAgo: string
    timefull: string
    command: string
    output: string
}

const LogComponent = memo(({id, title, timeAgo, timefull, command, output}: logsType) => {

    const [logType, setLogType] = useState<string>("log_min")

    const logAction = () => {
        if (logType === 'log_min') {
            setLogType("log_max")
        }
        else {
            setLogType("log_min")
        }
    }

    return (
        <div className="log_comp_cont">
            <div className={logType === "log_max" ? "log_max" : "log_min"} >
                <div className="log_info" onClick={() => logAction()}>
                    <div className="log_info_name">
                        <img src="/img/scriptsIcon/edit.svg" alt="" />
                        <div className="log_name_text">
                            <div className="log_text_title">{title}</div>
                            <div className="log_text_desc">{timeAgo}</div>
                        </div>
                    </div>
                    <img className="chevron" src="/img/chevron-down.svg" alt="down" />
                </div>
                <div className="log_fill">
                    <div className="log_fill_first">
                        <div className="log_first_inner">
                            <div className="log_inner_title">Time: </div>
                            <div className="log_inner_desc">{timefull}</div>
                        </div>
                        <div className="log_first_inner">
                            <div className="log_inner_title">ID:</div>
                            <div className="log_inner_desc">{id != "Pending" ? id.slice(0, 6) + "..." + id.slice(id.length-7, id.length - 1) : id}</div>
                        </div>
                    </div>
                    <section>
                        <div className="log_fill_second">
                            <div className="area_title">Command: </div>
                            <textarea readOnly className="second_textarea" value={command}></textarea>
                        </div>
                        <div className="log_fill_third">
                            <div className="area_title">Output: </div>
                            <textarea readOnly className="second_textarea" value={output}></textarea>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    )
})

export default LogComponent