import React, { memo, useState } from 'react';

import KanbanBoard from '../../components/kanbanComponent/KanbanBoard/KanbanBoard';
import MainLayout from '../../components/layout/mainLayout/Layout';
import SearchArea from '../../components/sectionsFilling/search/Search';
import CreateTaskButton from '../../components/tasksComponent/createTaskButton/CreateTaskButton';
import FreelanceTaskButton from '../../components/tasksComponent/freelanceTasksButton/FreelanceTaskButton';
import ListTaskComponent from '../../components/tasksComponent/listTaskComponent/ListTaskComponent';
import SedotTaskButton from '../../components/tasksComponent/sedotTasksButton/SedotTaskButton';
import ViewSwitcher from '../../components/tasksComponent/tasksViewSwitcher/ViewSwitcher';

import './Tasks.css';

const Tasks = memo(() => {
    const [type, setType] = useState<boolean>(true);
    const [serachValue, setSearchValue] = useState<string>('');
    const [clear, setClear] = useState<boolean>(true);
    const [find, setFind] = useState<any[]>([]);

    const tasksSearchHandler = () => {
        console.log();
    };

    return (
        <>
            <MainLayout>
                <div className="devices_page_container">
                    <div className="tasks_main_header">
                        <div className="tasks_button_header">
                            <SearchArea
                                searchVal={serachValue}
                                setSearchVal={setSearchValue}
                                findAction={tasksSearchHandler}
                                setClear={setClear}></SearchArea>
                            <CreateTaskButton />
                            {type && <FreelanceTaskButton />}
                            {type && <SedotTaskButton />}
                        </div>
                        <div className="tasks_switcher_header">
                            <ViewSwitcher
                                setSearchVal={setSearchValue}
                                setClear={setClear}
                                setFind={setFind}
                                type={type}
                                setType={setType}
                            />
                        </div>
                    </div>
                    {type ? (
                        <div className="tasks_main_cont">
                            <div className="tasks_main_title">All Tasks: 6</div>
                            <ListTaskComponent />
                        </div>
                    ) : (
                        <>
                            <KanbanBoard />
                        </>
                    )}
                </div>
            </MainLayout>
        </>
    );
});

export default Tasks;
