import React, { memo } from 'react';

import './ModalLayout.css';

type IconType = "text" | "user_plus" | "user" | "circle-people" | "clock" | "users" | "monitor" | "settings" | "script" | "script_plus" | "org" | 'terminal_comm';

type PropsType = {
    active: boolean;
    setActive: any;
    children: any;
    icon: IconType
    maximize: boolean;
    sectionName: string;
    headColor: string;
};

const ImgType = memo(({icon}: {icon: IconType}) => {
    switch (icon) {
        case 'text':
            return <img src="/img/popupIcons/file.svg" alt="text" />;
        case 'user_plus':
            return <img src="/img/popupIcons/user-plus.svg" alt="user_plus" />;
        case 'user':
            return <img src="/img/popupIcons/user.svg" alt="user" />;
        case 'circle-people':
            return <img src="/img/popupIcons/circle-people.svg" alt="user" />;
        case 'clock':
            return <img src="/img/popupIcons/clock.svg" alt="clock" />;
        case 'users':
            return <img src="/img/popupIcons/users.svg" alt="users" />;
        case 'monitor':
            return <img src="/img/popupIcons/monitor.svg" alt="monitor" />;
        case 'settings':
            return <img src="/img/popupIcons/settings.svg" alt="settings" />;
        case 'script':
            return <img src="/img/popupIcons/script.svg" alt="script" />;
        case 'script_plus':
            return <img src="/img/popupIcons/file-plus.svg" alt="script-plus" />;
        case 'terminal_comm':
            return <img src="/img/popupIcons/edit.svg" alt="terminal_comm" />;
        case 'org':
            return <img src="/img/popupIcons/org_icon.svg" alt="org" />;
        default:
            return <></>;
    }
});

const ModalLayout = memo(
    ({ active, setActive, children, maximize, icon, sectionName, headColor }: PropsType) => {
        return (
            <div className={active ? 'modal active' : 'modal'} onClick={() => setActive(false)}>
                <div className="modal_content" onClick={(e) => e.stopPropagation()}>
                    <div
                        className="popup_name_cont"
                        style={
                            headColor === 'blue'
                                ? {
                                      backgroundColor: 'rgba(21, 138, 240, 1)'
                                  }
                                : {
                                      backgroundColor: '#04CA7E'
                                  }
                        }>
                        <div className="popup_left_name_cont">
                            <ImgType icon={icon} />
                            <div className="popup_name_text">{sectionName}</div>
                        </div>
                        <div className="popup_right_name_cont">
                            {maximize && (
                                <img
                                    className="maximize_popup"
                                    src="/img/popupIcons/maximize.svg"
                                    alt=""
                                />
                            )}
                            <img
                                className="img_close_popup"
                                src="/img/popupIcons/x-circle.svg"
                                alt=""
                                onClick={() => setActive(false)}
                            />
                        </div>
                    </div>
                    {children}
                </div>
            </div>
        );
    }
);

export default ModalLayout;
