import React from 'react'

import './PermissionError.css'

type PropsType = {
    errorData: string
}

const PermissionError = ({ errorData }: PropsType) => {
    return (
        <div className='perm-err-cont'>
            <img style={{ width: '30px', height: '30px' }} src="/img/permissions/permissions_error.svg" alt="" />
            <div>
                {errorData}
            </div>
        </div>
    )
}

export default PermissionError
