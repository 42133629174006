import React, { memo } from 'react';

import './Search.css';

type SearchType = {
    setClear: React.Dispatch<React.SetStateAction<boolean>>,
    findAction: any;
    searchVal?: string;
    setSearchVal?: any
}

const SearchArea = memo(({setClear, findAction, searchVal, setSearchVal}: SearchType) => {
    return (
        <div className="search_area">
            <img className="search_img" src="/img/search.svg" alt="" />
            <input
                onChange={(e) => {
                    setSearchVal(e.target.value)
                    findAction(e.target.value);
                    if (!e.target.value) {
                        setClear(true)
                    } else {
                        setClear(false)
                    }
                }}
                value={searchVal}
                className="search_input"
                type="text"
                placeholder="Search"
            />
        </div>
    );
});

export default SearchArea;
