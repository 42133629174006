import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { makeFetch } from '../helpers';
import { RootState } from '../store';

import { setPermissions } from './UserSlice';


export type PeopleType = {
    id: string;
    email: string;
    name: string;
    image: string;
    acces: object[];
};

type PairUserType = {
    [key: string]: string;
};

type PeoplesType = {
    peoples: PeopleType[];
    pairUserName: PairUserType;
    loading: boolean;
    access: object[];
};

type InitialType = {
    teams: any[];
    loading: boolean;
};

const initialState: InitialType = {
    teams: [],
    loading: false
};

export const fetchTeams = createAsyncThunk<
any,
string,
{ state: RootState }
>('teams/get', async (orgId: string, {getState, dispatch}) => {
    const { success, data } = await makeFetch(
        `${process.env.REACT_APP_PERMISSIONS_URL}/groups/get/all`,
        'POST',
        { org_id: orgId },
        String(localStorage.getItem('__t'))
    );
    if (success) {
        const perm = data;
        if (perm.length !== 0) {
            const userId = getState().userReducer.id;
            const permSet = new Set<string>();
            for (let i = 0; i < perm.length; i++) {
                if (perm[i].users.includes(userId)) {
                    perm[i].permissions.forEach((permission: string) => permSet.add(permission));
                }
            }
            const permArr = Array.from(permSet);
            dispatch(setPermissions(permArr));
        }
        
        return data;
    }
});



export const teamSlice = createSlice({
    name: 'teams',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchTeams.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchTeams.fulfilled, (state, {payload}) => {
                state.loading = false;
                state.teams = payload;
            });
    }
});

export const fetchPeoples = createAsyncThunk('peoples/get', async (orgId: string) => {
    const { success, data } = await makeFetch(
        process.env.REACT_APP_ORG_URL + '/org/members',
        'POST',
        { orgId: orgId },
        String(localStorage.getItem('__t'))
    );
    if (success) {
        console.log(data)
        return data;
    } else {
        console.log(data)
    }
});

const peopleState: PeoplesType = {
    peoples: [],
    pairUserName: {},
    loading: false,
    access: []
};

export const peoplesSlice = createSlice({
    name: 'peoples',
    initialState: peopleState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchPeoples.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchPeoples.fulfilled, (state, action) => {
                state.loading = false;
                state.peoples = action.payload;
                const pair:any = {}
                action.payload.map((entry: any) => {pair[entry.email] = entry.name})
                state.pairUserName = pair
            });
    }
});

export const peoplesSliceReducer = peoplesSlice.reducer;
export const teamSliceReducer = teamSlice.reducer;
// export const {} = deviceSlice.actions;
// export const {} = peoplesSlice.actions;

// "id": "0d9992a29e56b0aff67549ffdd06684c57fe9a9c7e3ad6365bb321d5f4676beb",
// "email": "kupulenko29@gmail.com",
// "name": "Eugene K",
// "image": "",
// "access": []
