type events = "wesh_exec" | "wesh_write" | "wesh_remove_number" | "wesh_clear"

export const onExec = (callback: (command: string) => void): void => {
    window.addEventListener("wesh_exec", (data: any) => {
        callback(data.detail.data() as string);
    })
}

export const writeRow = (str: string): void => {
    notif("wesh_write", [str]);
}

export const writeBatch = (str: string): void => {
    const s: string[] = str.split("\n");
    notif("wesh_write", s);
}

export const removeRows = (num: number): void => {
    notif("wesh_remove_number", num.toFixed())
}

export const onRemoveRows = (callback: (rows: number) => void) => {
    window.addEventListener("wesh_remove_number", (data: any) => {
        callback(Number(data.detail.data()));
    })
}

export const onWriteRow = (callback: (row: string[]) => void) => {
    window.addEventListener("wesh_write", (data: any) => {
        callback(data.detail.data() as string[]);
    })
}

export const onClear = (callback: () => void) => {
    window.addEventListener("wesh_clear", () => {
        callback()
    })
}

export const notif = (event: events, data: any) => {
    window.dispatchEvent(new CustomEvent(event, {
        bubbles: false,
        detail: {
            data: () => data,
        }
    }))
}

export const clear = () => {
    notif("wesh_clear", [])
}