import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { makeFetch } from '../helpers';

type ScriptType = {
    id: string;
    description: string;
    execute: string;
    name: string;
    system: string;
    time: string
};

type InitialType = {
    scripts: ScriptType[];
    loading: boolean;
};

const initialState: InitialType = {
    scripts: [],
    loading: false
};

export const fetchScripts = createAsyncThunk('scripts/get', async (orgId: string) => {
    if (!orgId) {
        return [];
    }
    const { success, data } = await makeFetch(
        process.env.REACT_APP_ORG_URL + '/org/script/all/' + orgId,
        'GET',
        null,
        String(localStorage.getItem("__t"))
    );
    if (success) {
        return data.message
    } else {
        console.log(data);
    }

});

export const scriptsOrgSlice = createSlice({
    name: 'peoples',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchScripts.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchScripts.fulfilled, (state, action: any) => {
                state.loading = false;
                state.scripts = action.payload;
            });
    }
});

export const scriptsOrgSliceReducer = scriptsOrgSlice.reducer;
