import { Linear } from "../../helpers/Linear.js";

import { ModalComponent,ModalType, type } from "./index.type";

import "./index.styles.css";

const app: HTMLElement = document.getElementById('__modal__') as HTMLElement

let rendered = 0

const toRemove: Record<string, NodeJS.Timeout | null> = {};

const autoRemove = (item: number) => {
    toRemove[String(item)] = setTimeout(() => {
        const comp = document.getElementById("modal-comp-id-"+item) as HTMLElement
        app.removeChild(comp)
    }, 7000)
}

const immediatelyRemove = (item: number) => {
    const timeout = toRemove[String(item)];
    if (timeout) {
        clearTimeout(timeout);
        delete toRemove[String(item)];
    }
    const comp = document.getElementById("modal-comp-id-"+item) as HTMLElement
    app.removeChild(comp)
}

const ErrorComponent = ({ message }: ModalComponent) => {
    autoRemove(rendered)
    const toDelete = rendered;
    return Linear.createElement("div", {class: "error-modal-container modal-container", id: `modal-comp-id-${rendered}`}, [
        Linear.createElement("div", {class: "modal-image-container"}, 
            Linear.createElement("img", {class: "alert-img", src: "/img/modal/modal-error.svg", alt: "warning"}, null)
        ),
        Linear.createElement("div", {class: "modal-content"}, [
            Linear.createElement("h4", {class: "modal-head error-modal"}, "Error"),
            Linear.createElement("p", {class: "modal-body"}, message)
        ]),
        Linear.createElement("button", {
            class: "modal-remove-btn",
            action: {
                type: "click",
                listener: () => {immediatelyRemove(toDelete)}
            }
        }, 
            Linear.createElement("img", {src: "/img/modal/close-icon.svg"})
        )
    ])
}

const AlertComponent = ({ message }: ModalComponent) => {
    autoRemove(rendered)
    const toDelete = rendered;
    return Linear.createElement("div", {class: "alert-modal-container modal-container", id: `modal-comp-id-${rendered}`}, [
        Linear.createElement("div", {class: "modal-image-container"}, 
            Linear.createElement("img", {class: "alert-img", src: "/img/modal/modal-alert.svg", alt: "warning"}, null)
        ),
        Linear.createElement("div", {class: "modal-content"}, [
            Linear.createElement("h4", {class: "modal-head alert-modal"}, "Alert"),
            Linear.createElement("p", {class: "modal-body"}, message)
        ]),
        Linear.createElement("button", {
            class: "modal-remove-btn",
            action: {
                type: "click",
                listener: () => {immediatelyRemove(toDelete)}
            }
        }, 
            Linear.createElement("img", {src: "/img/modal/close-icon.svg"})
        )
    ])
}

const SuccessComponent = ({ message }: ModalComponent) => {
    autoRemove(rendered)
    const toDelete = rendered;
    return Linear.createElement("div", {class: "success-modal-container modal-container", id: `modal-comp-id-${rendered}`}, [
        Linear.createElement("div", {class: "modal-image-container"}, 
            Linear.createElement("img", {class: "alert-img", src: "/img/modal/modal-success.svg", alt: "warning"}, null)
        ),
        Linear.createElement("div", {class: "modal-content"}, [
            Linear.createElement("h4", {class: "modal-head alert-success"}, "Success"),
            Linear.createElement("p", {class: "modal-body"}, message)
        ]),
        Linear.createElement("button", {
            class: "modal-remove-btn",
            action: {
                type: "click",
                listener: () => {immediatelyRemove(toDelete)}
            }
        }, 
            Linear.createElement("img", {src: "/img/modal/close-icon.svg"})
        )
    ])
}

const ModalHandler = ({ type }: ModalType) => {
    rendered++
    
    if (type.error) {
        app.appendChild(ErrorComponent({message: type.message}))
    } else {
        if (type.success === true) {
            app.appendChild(SuccessComponent({message: type.message}))
        } else {
            app.appendChild(AlertComponent({message: type.message}))
        }
    }
}

export const createModal = (type: type) => {
    ModalHandler({type: type})
}