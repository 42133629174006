import React, {memo} from 'react'

import './KanbanTask.css'

type PropsType = {
    title: string
}

const KanbanTask = memo(({title}: PropsType) => {
  return (
    <div className='kanban_task_cont' draggable={true}>
      {title}
    </div>
  )
})

export default KanbanTask
