import React, {memo,useState} from 'react';

const CreateTaskButton = memo(() => {

  const [active,setActive] = useState<boolean>(false)

    return (
        <>
             <button className="add_devices_button_cont" onClick={() => setActive(true)}>
                <img className="devices_button_img" src="/img/add_icon.svg" alt="" />
                <div className="add_devices_text">Create Task</div>
            </button>
        </>
    );
});

export default CreateTaskButton;
