import React from "react";
import { memo } from "react";

import "./PeopleOverview.css"

const PeopleOverview = memo(() => {
    return (
        <div className="overview_container">
            <div style={{display:"flex",}} className="ava_text_cont">
                <img src="./img/people_avatar.svg" alt="" />
                <div className="overview_info_cont">
                    <div className="overview_name">John Doe</div>
                    <div className="overview_mail">me@softethica.com </div>
                </div>
            </div>
            <div className="overview_button">
                <div className="overview_button_text">Manage</div>
            </div>
        </div>
    )

})

export default PeopleOverview