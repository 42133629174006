import React, { memo, useCallback, useState } from 'react';

import usePermissions from '../../../helpers/checkPermission';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux';
import { permissionsName } from '../../../permissionsData/permissions';
import { makeFetch } from '../../../redux/helpers';
import { fetchScripts } from '../../../redux/reducers/ScriptsOrgSlice';
import { createModal } from '../../Modals';

import './ScriptPlate.css';

type PropsType = {
    showGreeAdd: boolean;
    id: string;
    title: string;
    description: string;
    os: string;
    time: string;
};

const ScriptPlate = memo(({ showGreeAdd, id, title, description, os, time }: PropsType) => {
    const { lastWisitedOrg } = useAppSelector((state) => state.userReducer);
    const dispatch = useAppDispatch()

    const { checkPermission } = usePermissions()


    const osImg = useCallback(() => {
        if (os === 'Linux') {
            return '/img/scripts/linux.svg';
        } else if (os === 'Windows') {
            return '/img/scripts/windows.svg';
        } else if (os === 'macOS') {
            return '/img/scripts/mac-script.svg';
        } else if (os === 'Raspberry') {
            return '/img/scripts/respberry.svg';
        }
    }, [os]);

    const addScriptAction = async () => {
        const body = {
            orgId: lastWisitedOrg,
            scriptId: id
        };
        const { success, data } = await makeFetch(
            process.env.REACT_APP_ORG_URL + '/org/add/script',
            'PUT',
            body,
            String(localStorage.getItem("__t"))
        );
        if (success) {
            createModal({ error: false, success: true, message: 'The script was successfully added to the organization' });
            dispatch(fetchScripts(lastWisitedOrg));
        }
        else {
            createModal({ error: true, message: data });
        }
    };

    return (
        <div className="script_plate_container">
            <img src={osImg()} />
            <div className="script_text_container">
                <div className="script_text_left">
                    <div className="script_title">{title}</div>
                    <div className="script_description">{description}</div>
                </div>
                <div className="script_text_right">
                    <div className="script_updated">Updated {time}</div>
                    <div className="script_img_container">
                        <div className="lock_img">
                            <img className="lock_img_content" src="/img/scripts/lock.svg" />
                            <div className="hover_lock_mess">
                                <div className="message_lock_container">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="133"
                                        height="30"
                                        viewBox="0 0 133 30"
                                        fill="none">
                                        <path
                                            d="M0.5 24C0.5 27.0376 2.96243 29.5 6 29.5H127C130.038 29.5 132.5 27.0376 132.5 24V14.1818C132.5 11.1443 130.038 8.68182 127 8.68182H95.4185C93.7375 8.68182 92.1219 8.03058 90.9108 6.86483L84.8915 1.07084C84.6891 0.87603 84.366 0.886131 84.1762 1.09321L79.1521 6.57403C77.9209 7.91711 76.1826 8.68182 74.3606 8.68182H6C2.96244 8.68182 0.5 11.1443 0.5 14.1818V24Z"
                                            fill="white"
                                            stroke="#158AF0"
                                        />
                                    </svg>
                                    <div className="message_lock_container_text">Free system </div>
                                </div>
                            </div>
                        </div>
                        <div className="circle_img">
                            <img
                                className="circle_img_content"
                                src="/img/scripts/check-circle.svg"
                            />
                            <div className="hover_circle_mess">
                                <div className="message_circle_container">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="133"
                                        height="30"
                                        viewBox="0 0 133 30"
                                        fill="none">
                                        <path
                                            d="M0.5 24C0.5 27.0376 2.96243 29.5 6 29.5H127C130.038 29.5 132.5 27.0376 132.5 24V14.1818C132.5 11.1443 130.038 8.68182 127 8.68182H125.419C123.738 8.68182 122.122 8.03058 120.911 6.86483L114.891 1.07084C114.689 0.87603 114.366 0.886131 114.176 1.09321L109.152 6.57403C107.921 7.91711 106.183 8.68182 104.361 8.68182H6C2.96243 8.68182 0.5 11.1443 0.5 14.1818V24Z"
                                            fill="white"
                                            stroke="#04CA7E"
                                        />
                                    </svg>
                                    <div className="message_circle_container_text">
                                        Verified Market{' '}
                                    </div>
                                </div>
                            </div>
                            <div></div>
                        </div>
                    </div>
                </div>
            </div>
            {checkPermission(permissionsName.marketplace.add_to_org) && <div
                onClick={addScriptAction}
                style={!showGreeAdd ? { display: 'none' } : {}}
                className="script_status_label script_status_add">
                Add Script
            </div>}
            <div
                style={showGreeAdd ? { display: 'none' } : {}}
                className="script_status_label script_status_is_added">
                Added
            </div>
        </div>
    );
});

export default ScriptPlate;
