import React, { memo } from 'react';

import { DeviceType } from '../../../redux/reducers/DeviceSlice';
import DevicesLog from '../devicesLog/DevicesLog';

import './ListDevicesLog.css';

type PropsType = {
    devices: DeviceType[];
    loading: boolean;
    find: any[];
    clear: boolean;
};

export const Loader = () => {
    return (
        <div className="animation_devices_container">
            <img className="animation_devices_loading" src="./img/loader.svg" />
        </div>
    );
};

const ListDevicesLog = ({ devices, loading, find, clear }: PropsType) => {
    return (
        <div className="list_device_cont">
            <div className="list_name_row">
                <div className="div1">OS</div>
                <div className="div2">Device Name</div>
                <div className="div3">Online Status</div>
                <div className="div4">Last Operated</div>
                <div className="div5">Health</div>
            </div>
            {loading ? (
                <Loader />
            ) : !clear ? (
                find.length ? (
                    find?.map((entry, key) => (
                        <DevicesLog
                            id={entry.id}
                            removeDev={true}
                            system={entry.system}
                            deviceId={entry.id}
                            key={key}
                            deviceName={''}
                            friendlyName={entry.device_name}
                            connectionStatus={entry.last_operated}
                            online={entry.online || false}
                        />
                    ))
                ) : (
                    <div
                        key={"devices_log_cont"}
                        className="devices_log_cont"
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            height: '50px'
                        }}>
                        DEVICE NOT FOUND
                    </div>
                )
            ) : !devices?.length ? (
                <div
                    key={"devices_log_cont_0"}
                    className="devices_log_cont"
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: '50px'
                    }}>
                    There are no devices yet
                </div>
            ) : (
                devices?.map((entry, key) => (
                    <DevicesLog
                        id={entry.id}
                        removeDev={true}
                        system={entry.system}
                        deviceId={entry.id}
                        key={key}
                        deviceName={''}
                        friendlyName={entry.device_name}
                        connectionStatus={entry.last_operated}
                        online={entry.online || false}
                    />
                ))
            )}
        </div>
    );
};

export default ListDevicesLog;
