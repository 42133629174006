import { createContext } from "react";

import { logsType } from "../components/devicesComponent/terminalDevicesLog/logComponent/LogComponent";

type context = {
    logs: logsType[]
    approvedLogs: logsType[]
    addLog: (a: logsType) => void
    addApprovedLog: (a: logsType) => void
}

const defaultContext: context = {
    logs: [],
    approvedLogs: [],
    addLog: (a: logsType): void => {
        console.log(a)
    },
    addApprovedLog: (a: logsType): void => {
        console.log(a)
    }
}

export const TerminalContext = createContext(defaultContext)