import React from "react";
import { memo } from "react"

import "./Bell.css"

const Bell = memo(() => {
    return (
        <div className="bell_container">
            <img className="bell" src="/img/bell.svg" alt="bell" />
            <sub style={{ color: "red", fontSize: '50px', marginLeft: "-14px", marginTop: "-48px", }}>.</sub>
        </div>
    )
})

export default Bell