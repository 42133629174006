import React, { memo, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Terminal from "../../../components/devicesComponent/devicesTerminal/Terminal";
import { logsType } from "../../../components/devicesComponent/terminalDevicesLog/logComponent/LogComponent";
import TerminalDevicesLog from "../../../components/devicesComponent/terminalDevicesLog/TerminalDevicesLog";
import MainLayout from "../../../components/layout/mainLayout/Layout";
import { TerminalContext } from "../../../context/terminalContext";
import usePermissions from "../../../helpers/checkPermission";
import { permissionsName } from "../../../permissionsData/permissions";

import "./DevicesTerminal.css";

const DevicesTerminal = memo(() => {
    const {checkPermission} = usePermissions()
    const navigation = useNavigate()

    const [logs, setLogs] = useState<logsType[]>([])
    const [approvedLogs, setApprovedLogs] = useState<logsType[]>([])

    useEffect(() => {
        !checkPermission(permissionsName.device.access_terminal) && navigation('/')
        document.title = 'SEDOT | Terminal';
    }, []);

    const addLog = useCallback((log: logsType) => {
        setLogs((prevLogs) => [log, ...prevLogs]);
    }, [setLogs])

    const addApprovedLog = useCallback((log: logsType) => {
        setApprovedLogs((prevLogs) => [log, ...prevLogs]);
    }, [setApprovedLogs])
    // console.log(logs)

    return (
        <MainLayout>
            <div className="terminal_page_container">
                <TerminalContext.Provider value={{logs, approvedLogs, addLog, addApprovedLog}}>
                    <Terminal/>
                    <TerminalDevicesLog/>
                </TerminalContext.Provider>
            </div>
        </MainLayout>
    )
})

export default DevicesTerminal