const SEPARATOR = "="

export const fromBaseString = (str: string) => {
    const strr = str.split(SEPARATOR);
    const dec: string[] = []

    for (let i = 0; i < strr.length; i++) {
        const sym = parseInt(strr[i], 16);
        dec.push(String.fromCharCode(sym))
    }

    return dec.join("");
}

export const toBaseString = (str: string): string => {
    const msg: string[] = [];
    for (let i = 0; i < str.length; i++) {
        const s = str.charCodeAt(i).toString(16);
        msg.push(s)
    }

    return msg.join(SEPARATOR);
}