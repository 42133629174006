import React, { memo } from "react";

import "./NoDevicesActivity.css"

type props = {
    text: string
}

const NoDevicesActivity = memo(({text}:props) => {
    return (
        <div className="nodevices_container">
            <div className="no_activity_cont" >
                <h3 className="no_activity_text">{text}</h3>
            </div>
        </div>
    )
})

export default NoDevicesActivity
