import React, { memo, useState } from "react";

import "./ViewSwitcher.css"

type PropsType = {
    type: boolean;
    setType: any;
    setSearchVal: any;
    setClear:any; 
    setFind:any
}

const ViewSwitcher = memo(({ type, setType,setSearchVal,setClear,setFind }: PropsType) => {

    const [view, setView] = useState<boolean>(type)

    const switchType = (el: boolean) => {
        setView(el)
        setType(el)
        setSearchVal("")
        setClear(true)
        setFind([])
    }

    return (
        <div className="view_switcher_container" >
            <div
                className="view_switcher first_switch"
                style={view ? { backgroundColor: "#FFFFFF" } : {}}
                onClick={() => switchType(true)}
            >
                <img src="/img/viewSwitcher/monitor.svg" alt="" />
            </div>
            <div
                style={!view ? { backgroundColor: "#FFFFFF" } : {}}
                className="view_switcher second_switch"
                onClick={() => switchType(false)}
            >
                <img src="/img/viewSwitcher/list.svg" alt="" />
            </div>
        </div>
    )
})

export default ViewSwitcher