import React from 'react';

import './ListTaskComponent.css';

const ListTaskComponent = () => {
    return (
        <div className="list_task_cont">
            <div className='list_task_left'>
                <img src="/img/tasks/task_red.svg" alt="task status" />
                <span className='list_task_title'>GASGd asgda asd</span>
            </div>
            {/* <div className='list_task_sedot'>SEDOT</div> */}
            {/* <div className='list_task_user_cont'>
                <img className='list_task_avatar' src="/img/avatar.svg" alt="avatar" />
                <span className='list_task_name'>Name</span>
            </div> */}
            <div className='list_task_freelance'>150$</div>

        </div>
    );
};

export default ListTaskComponent;
