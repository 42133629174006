import React, { memo, useCallback,useEffect, useRef, useState } from 'react';

import DevicesActivity from '../../components/devicesComponent/devicesActivity/DevicesActivity';
import NoDevicesActivity from '../../components/devicesComponent/devicesActivity/noDevicesActivity/NoDevicesActivity';
import MainLayout from '../../components/layout/mainLayout/Layout';
import { WeekStatistic } from '../../components/mainComponent/overloadStatistic/WeekStatistic';
import PeopleOverview from '../../components/mainComponent/peopleOverview/PeopleOverview';
import Progressbar from '../../components/mainComponent/progressbar/Progressbar';
import Tasks from '../../components/mainComponent/tasks/Tasks';
import NameSection from '../../components/sectionsFilling/nameSection/NameSection';
import { DevicesActivityContext } from '../../context/devicesActivityContext';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { setAudits } from '../../redux/reducers/DeviceSlice';
import { AuditsRenderer } from '../devices/devicesMain/Devices';

import './Main.css';

const Main = memo(() => {
    useEffect(() => {
        document.title = 'SEDOT | Dashboard';
    }, []);

    const [auditsArr, setAuditsArr] = useState<any[]>([])

    const dispatch = useAppDispatch()
    
    const { lastWisitedOrg } = useAppSelector((state) => state.userReducer);
    const { pairUserName } = useAppSelector((state) => state.peopleReducer);
    const { audits, auditIsLoading, devices} = useAppSelector(
        (store) => store.deviceReducer
    );

    // const [devices, setDevices] = useState()
    // const [tasks, setTasks] = useState()


    return (
        <>
                <div className="main_page_container">
                    <div className="three_el_container">
                        <div className="element fs_el">
                            <div className="inner_container">
                                <NameSection title={'Get started'} subtitle={'Tips & Tricks'} />
                                <div
                                    className="content_container_small"
                                    style={{ overflow: 'hidden' }}>
                                    <Progressbar />
                                </div>
                            </div>
                        </div>
                        <div className="second_element">
                            <div className="inner_container">
                                <NameSection
                                    title={'Week Overload'}
                                    subtitle={'Device week overload statistic'}
                                />
                                <div className="content_container_small">
                                    <div className="statistic" style={{ marginTop: '10px' }}>
                                        {/* <WeekStatistic /> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="element th_el">
                            <div className="inner_container">
                                <NameSection
                                    title={'People Overview'}
                                    subtitle={'Fast user managment'}
                                />
                                <div className="content_container_small">
                                    <PeopleOverview />
                                    <PeopleOverview />
                                    <PeopleOverview />
                                    <PeopleOverview />
                                    <div className="add_user_container">
                                        <img className="add_icon" src="./img/add_icon.svg" alt="" />
                                        <div className="add_text_cont">
                                            <div className="add_title">Add User</div>
                                            <div className="add_subtitle">
                                                Easily add new users to your organization{' '}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="two_el_container">
                        <div className="half_element dev_el">
                            <div className="inner_container">
                                <NameSection
                                    title={'Devices Activity'}
                                    subtitle={'Latest logged activity on devices in organization'}
                                />
                                <div className="content_container" >
                                    {!audits?.length ? (
                                        <NoDevicesActivity text={'No devices activity'} />
                                    ) : (
                                        <AuditsRenderer
                                            audits={audits}
                                            pairUserName={pairUserName}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="half_element tasks_el">
                            <div className="inner_container">
                                <NameSection
                                    title={'Tasks'}
                                    subtitle={'Quick and convenient way to manage team tasks'}
                                />
                                <div className="content_container">
                                    {/* <NoDevicesActivity text={"No open tasks"}/>  */}
                                    <Tasks status="in_work" />
                                    <Tasks status="done" />
                                    <Tasks status="expired" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        </>
    );
});

export default Main;

// const AuditsRenderer = () => {

//     return (
//         <DevicesActivityContext.Provider>
//             {audits.slice(0,19).map((entry) => {
//                 return (
//                     <DevicesActivity
//                         key={entry.tx}
//                         userEmail={entry.user_id}
//                         user={pairUserName[entry.user_id]}
//                         transId={entry.tx}
//                         time={entry.timestamp}
//                                                     data={entry.data}
//                                                     deviceName={entry.device_name}
//                                                     system={entry.system}
//                                                 />
//                                             );
//                                         })}
//         </DevicesActivityContext.Provider>
//     )
// }