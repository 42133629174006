import { useAppSelector } from "../hooks/redux"

const usePermissions = () => {
    const { permissions } = useAppSelector(state => state.userReducer);

    const checkPermission = (permission: string) => {
        return permissions.some(p => p.action[0] === permission || p.action[0] === 'org:all');
    };

    return { checkPermission };
}

export default usePermissions;
