import React, { memo, useEffect, useState } from 'react';

import './FiltersComponent.css';

type CheckboxType = {
    title: string;
    id: any;
};

type FiltersType = {
    title: string;
    children: any;
};

export const generateUniqueId = (key:any) =>  {
    return Math.random().toString(36).substring(2) + Date.now().toString(36) + key;
}

const CustomCheckbox = ({ title, id }: CheckboxType) => {
    const [check, setCheck] = useState<boolean>(true);
    const checkAct = () => {
        if (check) {
            setCheck(false);
        } else {
            setCheck(true);
        }
    };
    return (
        <div className="custom_checkbox_container">
            <input
                className="custom_checkbox"
                checked={check}
                type="checkbox"
                name={id}
                value="check"
                id={id}
                onChange={(e) => checkAct()}
            />
            <label className="custom_checkbox_label" htmlFor={id}>
                {title}
            </label>
        </div>
    );
};

const FiltersContainer = memo(({ children, title }: FiltersType) => {
    const [logType, setLogType] = useState<boolean>(false);

    const logAction = () => {
        if (logType) {
            setLogType(false);
        } else {
            setLogType(true);
        }
    };
    return (
        <div className="filters_container">
            <div className={!logType ? 'filters_min' : 'filters_max'}>
                <div className="filters_info" onClick={() => logAction()}>
                    <div>{title}</div>
                    <img className="chevron" src="./img/chevron-down.svg" alt="arrow" />
                </div>
                <div className="filters_inner">
                    <div>{children}</div>
                </div>
            </div>
        </div>
    );
});

const FiltersComponent = memo(() => {
    const typeFlter: string[] = ['System', 'Marketplace', 'Custom'];
    const osFilter: string[] = ['Linux', 'Windows', 'Raspberry', "macOS"];

    return (
        <div>
            <div>
                <div className="filter_show_available">
                    <CustomCheckbox id={"sh2av"} title="Show available" />
                </div>
                <div>
                    <FiltersContainer title="Type">
                        {typeFlter.map((entry, key) => {return <CustomCheckbox key={key} id={generateUniqueId(key)} title={entry}/>})}
                    </FiltersContainer>
                    <FiltersContainer title="Operation system">
                        {osFilter.map((entry, key) => {return <CustomCheckbox key={key} id={generateUniqueId(key)} title={entry}/>})}
                    </FiltersContainer>
                </div>
            </div>
        </div>
    );
});

export default FiltersComponent;
