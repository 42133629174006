import React, { lazy, memo, Suspense, useCallback, useEffect, useState } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';

import { ws } from './components/devicesComponent/devicesTerminal/SingleWebSocket';
import { createModal } from './components/Modals';
import { useAppSelector } from './hooks/redux';
const Audit = lazy(() => import('./pages/audit/Audit'));
const Dayreview = lazy(() => import('./pages/devices/devicesDayreview/Dayreview'));
const Devices = lazy(() => import('./pages/devices/devicesMain/Devices'));
import { fetchClusters } from './redux/reducers/ClustersSlice';
const Teams = lazy(() => import('./pages/teams/Teams'))
import MainLayout from './components/layout/mainLayout/Layout';
import DevicesTerminal from './pages/devices/devicesTerminal/DevicesTerminal';
import { Freelance } from './pages/freelance/Freelance';
import Login from './pages/login/login/Login';
import LoginOrganization from './pages/login/loginOrganization/LoginOrganization';
import Main from './pages/main/Main';
import Scripts from './pages/marketplace/scripts/Scripts';
import ScriptsTerminal from './pages/marketplace/terminal/ScriptsTerminal';
import Page404 from './pages/page404/Page404';
import Signup from './pages/signup/signupPage/Signup';
const SwitchOrganization = lazy(() => import('./pages/switchOrg/SwitchOrganization'));
import Tasks from './pages/tasks/Tasks';
import { makeFetch } from './redux/helpers';
import {
    fetchAuditsFirst,
    fetchDevicesId,
    setAudits
} from './redux/reducers/DeviceSlice';
import { fetchPeoples, fetchTeams } from './redux/reducers/TeamSlice';
// import { fetchScripts } from './redux/reducers/ScriptsOrgSlice';
import { addUserData } from './redux/reducers/UserSlice';
import { useAppDispatch } from './redux/store';

export const Navigation = () => {
    const dispatch = useAppDispatch();
    const navigation = useNavigate();
    const [prevUserUrl, setPrevUserUrl] = useState<string>("")

    const { audits, devices } = useAppSelector((state) => state.deviceReducer);
    const { lastWisitedOrg } = useAppSelector((state) => state.userReducer);

    const getUserData = useCallback(async (t: any, r: any) => {
        const out = () => {
            localStorage.setItem('__account', 'false');
            navigation('/login');
        };

        if (!t?.length || !r?.length) {
            out();
        } else {
            const url = process.env.REACT_APP_AUTORIZE_URL + '/auth/user';
            const { success, data } = await makeFetch(url, 'GET', null, t);
            if (success) {
                localStorage.setItem('__account', 'true');
                dispatch(addUserData(data));
                if (!data?.lastWisitedOrg?.length) {
                    navigation('/freelance');
                }
            } else {
                createModal({ error: false, message: 'Session has been closed' });
                out();
            }
        }
    }, []);

    const load = useCallback(async () => {
        const body = document.getElementsByTagName('body')[0] as HTMLElement;
        body.style.overflow = 'hidden';
        const acc = localStorage.getItem('__account');
        const t = localStorage.getItem('__t');
        const r = localStorage.getItem('__r');
        const loader = document.getElementById('loader-overflow') as HTMLElement;
        const href = window.location.pathname;
        if (!href.includes('login') && !href.includes('signup')) {
            if (acc != 'true') {
                await getUserData(t, r);
            } else {
                await getUserData(t, r);
            }
        }
        body.style.overflow = 'auto';
        loader.style.display = 'none';
    }, [getUserData]);
    useEffect(() => {
        load();
    }, []);

    useEffect(() => {
        if (prevUserUrl.includes("terminal")) {
            dispatch(fetchAuditsFirst({ orgId: lastWisitedOrg, limit: 50, offset: 0 }))
        }
        console.log(prevUserUrl)
        setPrevUserUrl(window.location.pathname)

        if (!window.location.pathname.includes("terminal")) {
            if (ws().readyState == ws().OPEN && ws().readyState != ws().CLOSED) {
                ws().close()
                window.termConnected = false
            }
        }
    }, [window.location.pathname])



    useEffect(() => {
        if (lastWisitedOrg) {
            dispatch(fetchTeams(lastWisitedOrg))
            dispatch(fetchDevicesId(lastWisitedOrg));
            dispatch(fetchPeoples(lastWisitedOrg));
            dispatch(fetchClusters(lastWisitedOrg));
            dispatch(fetchAuditsFirst({ orgId: lastWisitedOrg, limit: 50, offset: 0 }));
        }
    }, [lastWisitedOrg]);

    useEffect(() => {
        const mergedArray: any = [];
        if (devices.length) {
            audits.forEach((device) => {
                const matchingData = devices.find((data) => data.id === device.device_id);
                if (matchingData) {
                    const mergedObject = {
                        ...device,
                        system: matchingData.system,
                        device_name: matchingData.device_name
                    };
                    mergedArray.push(mergedObject);
                }
            });
            dispatch(setAudits(mergedArray));
            // setAuditsArr(mergedArray)
        }
    }, [devices]);

    return (
        <Routes>
            <Route path="/signup" element={<Signup />} />
            <Route path="/login" element={<Login />} />
            <Route path="/login_as_organization" element={<LoginOrganization />} />
            <Route
                path="/user/switch_organization"
                element={
                    <MainLayout>
                        <Suspense fallback={<p>Loading</p>}>
                            <SwitchOrganization />
                        </Suspense>
                    </MainLayout>
                }
            />
            <Route
                path="/"
                element={
                    <MainLayout>
                        <Suspense fallback={<p>Loading</p>}>
                            <Main />
                        </Suspense>
                    </MainLayout>
                }
            />
            <Route
                path="/devices"
                element={
                    <MainLayout>
                        <Suspense fallback={<p>Loading</p>}>
                            <Devices />
                        </Suspense>
                    </MainLayout>
                }
            />
            <Route
                path="/devices/dayreview"
                element={
                    <MainLayout>
                        <Suspense fallback={<p>Loading</p>}>
                            <Dayreview />
                        </Suspense>
                    </MainLayout>
                }
            />
            <Route path="/devices/terminal" element={<DevicesTerminal />} />
            <Route
                path="/audit"
                element={
                    <MainLayout>
                        <Suspense fallback={<p>Loading</p>}>
                            <Audit />
                        </Suspense>
                    </MainLayout>
                }
            />
            <Route
                path="/teams"
                element={
                    <MainLayout>
                        <Suspense fallback={<p>Loading</p>}>
                            <Teams />
                        </Suspense>
                    </MainLayout>
                }
            />
            <Route path="/scripts" element={<Scripts />} />
            <Route path="/term" element={<ScriptsTerminal />} />
            <Route path="/tasks" element={<Tasks />} />
            <Route path="/freelance" element={<Freelance />} />
            <Route path="*" element={<Page404 />} />
        </Routes>
    );
};
