import React, {memo,useState} from 'react';

const FreelanceTaskButton = memo(() => {

  const [active,setActive] = useState<boolean>(false)

    return (
        <>
            <button className="add_devices_button_cont" onClick={() => setActive(true)}>
                <img className="devices_button_img" src="/img//tasks/freel_task.svg" alt="" />
                <div style={{marginLeft:"15px"}} className="add_devices_text">Freelance Task</div>
            </button>
        </>
    );
});

export default FreelanceTaskButton;
