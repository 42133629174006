import { clear, onExec, removeRows, writeBatch, writeRow } from "./handlers";
import { Wesh } from "./Term";

export const wesh = {
    Term: Wesh,
    writeBatch,
    writeRow,
    onExec,
    removeRows,
    clear
}