import React, { useCallback, useEffect, useReducer, useState } from 'react';

import AboutCommPopup from '../../../components/devicesComponent/aboutCommPopup/AboutCommPopup';
import DevicesActivity from '../../../components/devicesComponent/devicesActivity/DevicesActivity';
import NoDevicesActivity from '../../../components/devicesComponent/devicesActivity/noDevicesActivity/NoDevicesActivity';
import DevicesClaster from '../../../components/devicesComponent/devicesClaster/DevicesClaster';
import ListDevicesLog from '../../../components/devicesComponent/listDevicesLog/ListDevicesLog';
import StatisticsMenu from '../../../components/devicesComponent/statisticsMenu/StatisticsMenu';
import PermissionError from '../../../components/permissionError/PermissionError';
import AddDevicesButton from '../../../components/sectionsFilling/addDevices/AddDevicesButton';
import CreateClusterButton from '../../../components/sectionsFilling/createCluster/CreateClusterButton';
import NameSection from '../../../components/sectionsFilling/nameSection/NameSection';
import SearchArea from '../../../components/sectionsFilling/search/Search';
import ViewSwitcher from '../../../components/sectionsFilling/viewSwitcher/ViewSwitcher';
import { DevicesActivityContext } from '../../../context/devicesActivityContext';
import usePermissions from '../../../helpers/checkPermission';
import { useAppSelector } from '../../../hooks/redux';
import { permissionsName } from '../../../permissionsData/permissions';
import { fetchClusters } from '../../../redux/reducers/ClustersSlice';
import { AuditType } from '../../../redux/reducers/DeviceSlice';
import { useAppDispatch } from '../../../redux/store';

import './Devices.css';

const Devices = () => {
    const [devType, setDevType] = useState<boolean>(true);

    const [serachValue, setSearchValue] = useState<string>('');

    const [find, setFind] = useState<any[]>([]);
    const [clear, setClear] = useState<boolean>(true);

    const { lastWisitedOrg } = useAppSelector((state) => state.userReducer);
    const { audits, devices, loading, permissionsError } = useAppSelector((state) => state.deviceReducer);
    const { pairUserName } = useAppSelector((state) => state.peopleReducer);

    const { clusters, loading: loadingClusters } = useAppSelector(
        (state) => state.clustersSliceReducer
    );

    const { permissions } = useAppSelector((state) => state.userReducer)
    const { checkPermission } = usePermissions()

    const dispatch = useAppDispatch();

    const deviceSearchHandler = useCallback(
        (value: string) => {
            if (value.length) {
                const finder: any[] = [];
                for (let i = 0; i < devices.length; i++) {
                    const searchData: string = value.toLowerCase();
                    if (devices[i].device_name.toLowerCase().includes(searchData)) {
                        console.log(devices[i].device_name);
                        finder.push(devices[i]);
                    }
                }
                setFind(finder);
            }
        },
        [devices, find]
    );
    // useEffect(() => {console.log(devices)})

    const clustersSearchHandler = useCallback(
        (value: string) => {
            if (value.length) {
                const finder: any[] = [];
                for (let i = 0; i < clusters.length; i++) {
                    const searchData: string = value.toLowerCase();
                    if (clusters[i].name.toLowerCase().includes(searchData)) {
                        finder.push(clusters[i]);
                    }
                }
                setFind(finder);
            }
        },
        [clusters, find]
    );

    useEffect(() => {
        document.title = 'SEDOT | Devices';
    }, []);

    // const { lastWisitedOrg } = useAppSelector((state) => state.userReducer);
    // useEffect(() => {
    //     dispatch(fetchDevicesId(lastWisitedOrg));
    // }, [lastWisitedOrg]);

    return (
        <div className="devices_page_container">
            <div className="all_page_title">{devType ? "Devices" : "Clusters"}</div>
            <div className="devices_page_header_cont">
                <div className="devices_page_header_first">
                    {devType ? checkPermission(permissionsName.devices.view) && (
                        <SearchArea
                            searchVal={serachValue}
                            setSearchVal={setSearchValue}
                            findAction={deviceSearchHandler}
                            setClear={setClear}
                        />
                    ) : checkPermission(permissionsName.devices.clusters.view) && (
                        <SearchArea
                            searchVal={serachValue}
                            setSearchVal={setSearchValue}
                            findAction={clustersSearchHandler}
                            setClear={setClear}
                        />
                    )}
                    {(checkPermission(permissionsName.devices.add) || checkPermission(permissionsName.devices.add_only_with_os) )  && <AddDevicesButton clusters={clusters} />}
                    {!devType ? checkPermission(permissionsName.devices.clusters.create) && <CreateClusterButton /> : <></>}
                </div>
                <div>
                    <ViewSwitcher
                        setSearchVal={setSearchValue}
                        setClear={setClear}
                        setFind={setFind}
                        type={devType}
                        setType={setDevType}
                    />
                </div>
            </div>
            <div className="devices_inner_container">
                <div className="left_content_container">
                    {devType ? permissionsError ? <div style={{ marginRight: "20px" }}><PermissionError errorData={permissionsError} /></div> : (
                        <>
                            <ListDevicesLog
                                clear={clear}
                                find={find}
                                loading={loading}
                                devices={devices}
                            />
                        </>
                    ) : !clear ? (
                        find.length ? (
                            <>
                                {find.map((entry, key) => {
                                    return (
                                        <DevicesClaster
                                            title={entry?.name}
                                            all={entry?.devices?.length}
                                            // online={4}
                                            // offline={2}
                                            key={key}
                                            devices={entry?.devices}
                                            id={entry?.id}
                                        />
                                    );
                                })}
                            </>
                        ) : (
                            <div
                                className="devices_log_cont"
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    height: '50px',
                                    backgroundColor: "white"
                                }}>
                                CLUSTER NOT FIND
                            </div>
                        )
                    ) : (

                        !checkPermission(permissionsName.devices.clusters.view) ?
                            <div style={{ marginRight: "20px" }}>
                                <PermissionError errorData={'You do not have permission to view cluster list with fields'} />
                            </div>
                            :
                            clusters?.length ?
                                <>
                                    {clusters?.map((entry, key) => {
                                        return (
                                            <DevicesClaster
                                                title={entry?.name}
                                                all={entry?.devices?.length}
                                                // online={4}
                                                // offline={2}
                                                key={key}
                                                devices={entry?.devices}
                                                id={entry?.id}
                                            />
                                        );
                                    })}
                                </> : <div
                                    className="devices_log_cont"
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        height: '50px',
                                        backgroundColor: "white"
                                    }}>
                                    There are no clusters yet
                                </div>
                    )}
                </div>
                <div className="right_content_container">
                    <StatisticsMenu key={0} devices={devices} />
                    <div className="devices_act_cont">
                        <div className="dev_inner_container">
                            <div className="name_section_cont">
                                <NameSection
                                    title={'Devices Activity'}
                                    subtitle={
                                        'Latest logged activity on devices in organization'
                                    }
                                />
                            </div>
                            <div className="activity_dev_content_container">
                                {!audits?.length ? (
                                    <NoDevicesActivity text={'No devices activity'} />
                                ) : (<AuditsRenderer
                                    audits={audits}
                                    pairUserName={pairUserName}
                                />)}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

type rendererProps = {
    audits: AuditType[]
    pairUserName: any
}

export const AuditsRenderer = ({ audits, pairUserName }: rendererProps) => {
    const [active, setActive] = useState<boolean>(false);
    const [about, setAbout] = useState({
        commandTime: "",
        commandTransId: "",
        commandUser: "",
        userEmail: "",
        ethData: ""
    });

    const setAboutHandler = useCallback((data: any) => {
        setAbout({
            commandTime: data.commandTime,
            commandTransId: data.commandTransId,
            commandUser: data.commandUser,
            userEmail: data.userEmail,
            ethData: data.ethData
        })
    }, [])

    console.log(audits[4].user_id)

    return (
        <DevicesActivityContext.Provider value={{ ...about, setAbout: setAboutHandler }}>
            {audits.slice(0, 19).map((entry) => {
                return (
                    <DevicesActivity
                        setActive={setActive}
                        key={entry.tx}
                        userEmail={entry.user_id}
                        user={pairUserName[entry.user_id]}
                        transId={entry.tx}
                        time={entry.timestamp}
                        data={entry.data}
                        deviceName={entry.device_name}
                        system={entry.system}
                    />
                );
            })}
            <AboutCommPopup
                active={active}
                setActive={setActive}
            />
        </DevicesActivityContext.Provider>
    )
}

export default Devices;
