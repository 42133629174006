import { createContext } from "react"

import { systemTypes } from "../redux/reducers/DeviceSlice";

type context = {
    commandTime: any;
    commandTransId: any;
    commandUser: string;
    userEmail: string;
    ethData: string
    setAbout: (data: any) => void
}

const defaultContext: context = {
    commandTime: "",
    commandTransId: "",
    commandUser: "",
    userEmail: "",
    ethData: "",
    setAbout: (data: any) => {console.log(data)}
}

export const DevicesActivityContext = createContext(defaultContext)