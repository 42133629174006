import { useDispatch } from "react-redux";
import { combineReducers, configureStore } from "@reduxjs/toolkit";

import { clustersSliceReducer } from './reducers/ClustersSlice';
import deviceReducer from "./reducers/DeviceSlice"
import { scriptsOrgSliceReducer } from './reducers/ScriptsOrgSlice';
import { peoplesSliceReducer, teamSliceReducer} from "./reducers/TeamSlice"
import userReducer from "./reducers/UserSlice"



const rootReducer = combineReducers({
    userReducer,
    deviceReducer,
    peopleReducer: peoplesSliceReducer,
    teamsReduser: teamSliceReducer,
    scriptsOrgSliceReducer,
    clustersSliceReducer
    

})

export const store = configureStore({
    reducer: rootReducer,
})

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();