import React, { useEffect } from 'react';
import { memo, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import { useAppSelector } from '../../../hooks/redux';
import { logoutUser } from '../../../redux/reducers/UserSlice';
import { useAppDispatch } from '../../../redux/store';

import Bell from './bell/Bell';
import LogoImg from './logoImg/LogoImg';

import './Layout.css';

type PropTypes = {
    className?: string;
    children: React.ReactElement | JSX.Element;
};

type LogoutProps = {
    userLogout: () => void;
    logout: string;
    name: string;
};

const LogoutItem = memo(({ userLogout, logout, name }: LogoutProps) => {
    return (
        <div
            className={'logout_popup' + ' ' + `logout_popup_${logout}`}
            onClick={(event) => {
                event.stopPropagation();
            }}>
            <div className="window_cont">
                <div className="small_square"></div>
                <div className="logout_popup_container">
                    <div className="logout_popup_name">
                        <div className="logout_popup_name_top">Hello, {name}</div>
                        <div className="logout_popup_name_org">organization</div>
                    </div>
                    <div className="logout_popup_center">
                        <div className="logout_popup_center_inner">
                            <div className="logout_popup_button">Q&A</div>
                            <Link to="/user/switch_organization" className="logout_popup_button">
                                Switch orginazation
                            </Link>
                            <div className="logout_popup_button">Settings</div>
                            <div className="log_line"></div>
                        </div>
                        <div className="logout_button" onClick={userLogout}>
                            <div> Log out </div>
                            <img src="/img/authorization/logout_icon.svg" alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
});

const MainLayout = memo(({ className, children }: PropTypes) => {
    const loc = useLocation();
    const navigate = useNavigate();
    const location = loc.pathname;

    const dispatch = useAppDispatch();

    const [windowSize, setWindowSize] = useState(window.innerWidth);

    const debounce = <T extends any[]>(func: (...args: T) => void, delay: number) => {
        let timeoutId: ReturnType<typeof setTimeout>;

        return (...args: T) => {
            clearTimeout(timeoutId);
            timeoutId = setTimeout(() => {
                func(...args);
            }, delay);
        };
    };

    useEffect(() => {
        const handleResize = debounce((event: Event) => {
            setWindowSize(window.innerWidth);
        }, 500);

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (!localStorage.getItem('menu_type')) {
            localStorage.setItem('menu_type', 'menu-min');
        }
    }, []);

    const def = localStorage.getItem('menu_type');

    const [menuType, setMenuType] = useState<any>(def);
    const [menuText, setMenuText] = useState({ display: 'auto' });

    const menuAction = () => {
        if (menuType === 'menu-max') {
            setMenuType('menu-min');
            localStorage.setItem('menu_type', 'menu-min');
        } else {
            setMenuType('menu-max');
            localStorage.setItem('menu_type', 'menu-max');
        }
    };

    const [logout, setLogout] = useState<string>('close');

    const logoutAction = () => {
        if (logout === 'open') {
            setLogout('close');
        } else setLogout('open');
    };

    const userLogout = () => {
        dispatch(logoutUser());
        localStorage.removeItem('__account');
        localStorage.removeItem('__t');
        localStorage.removeItem('__r');
        navigate('/login');
    };

    const { name } = useAppSelector((state) => state.userReducer);

    return (
        <div
            className={'body' && menuType === 'menu-max' ? 'max' : 'min'}
            style={{ minHeight: 'calc(100vh - 19px)' }}
            onClick={() => {
                if (logout === 'open') {
                    setLogout('close');
                }
            }}>
            <div className="back_blur"></div>
            <header
                className={`${className ? className + 'hd' : ''} ${
                    menuType === 'menu-min' ? 'header_min' : 'header_max'
                }`}>
                <div className="logo">SoftEthic DevOps Tools</div>
                <div className="logo_adaptiv">SEDOT</div>
                <div className="header_info_container">
                    <Bell />
                    <div className="user_information_container" onClick={() => logoutAction()}>
                        <div className="user_name">
                            <div>{name}</div>
                        </div>
                        <LogoImg />
                    </div>
                    <LogoutItem logout={logout} name={name} userLogout={userLogout} />
                </div>
            </header>
            <aside className={`${className ? className : ''} ${menuType}`}>
                <div className="burger_container">
                    <div className="burger_menu" onClick={() => menuAction()}>
                        <img className="burger_img" src="/img/burgerMenu.svg" alt="menu" />
                    </div>
                </div>
                <div
                    className="aside-menu"
                    onClick={() => {
                        if (windowSize < 1025) {
                            setMenuType('menu-min');
                            localStorage.setItem('menu_type', 'menu-min');
                        }
                    }}>
                    <ul>
                        <li>
                            <Link
                                className={location === '/' ? 'link_active' : 'navigation_link'}
                                to="/">
                                <svg
                                    width="25"
                                    height="25"
                                    viewBox="0 0 28 25"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M27.9927 12.4756C27.9927 13.3545 27.2634 14.043 26.437 14.043H24.8813L24.9153 21.8652C24.9153 21.9971 24.9056 22.1289 24.891 22.2607V23.0469C24.891 24.126 24.0208 25 22.9464 25H22.1686C22.1151 25 22.0616 25 22.0081 24.9951C21.9401 25 21.872 25 21.8039 25H20.2239H19.0572C17.9828 25 17.1126 24.126 17.1126 23.0469V21.875V18.75C17.1126 17.8857 16.4174 17.1875 15.5569 17.1875H12.4455C11.585 17.1875 10.8898 17.8857 10.8898 18.75V21.875V23.0469C10.8898 24.126 10.0196 25 8.94521 25H7.77844H6.22761C6.15469 25 6.08177 24.9951 6.00884 24.9902C5.95051 24.9951 5.89217 25 5.83383 25H5.05599C3.98159 25 3.11138 24.126 3.11138 23.0469V17.5781C3.11138 17.5342 3.11138 17.4854 3.11624 17.4414V14.043H1.55569C0.680613 14.043 0 13.3594 0 12.4756C0 12.0361 0.145846 11.6455 0.486152 11.3037L12.9511 0.390625C13.2914 0.0488281 13.6803 0 14.0206 0C14.3609 0 14.7499 0.0976562 15.0416 0.341797L27.4579 11.3037C27.8468 11.6455 28.0413 12.0361 27.9927 12.4756Z"
                                        fill="#9A9FB5"
                                    />
                                </svg>
                                <span style={menuText} className="menu-text">
                                    Dashboard
                                </span>
                            </Link>
                        </li>
                        <li>
                            <Link
                                to="/devices"
                                className={
                                    location === '/devices' ||
                                    location === '/devices/dayreview' ||
                                    location === '/devices/terminal'
                                        ? 'link_active'
                                        : 'navigation_link'
                                }>
                                <svg
                                    width="25"
                                    height="25"
                                    viewBox="0 0 28 25"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M3.11111 0C1.39514 0 0 1.40137 0 3.125V17.1875C0 18.9111 1.39514 20.3125 3.11111 20.3125H11.6667L11.1465 21.875H7.77778C6.91736 21.875 6.22222 22.5732 6.22222 23.4375C6.22222 24.3018 6.91736 25 7.77778 25H20.2222C21.0826 25 21.7778 24.3018 21.7778 23.4375C21.7778 22.5732 21.0826 21.875 20.2222 21.875H16.8535L16.3333 20.3125H24.8889C26.6049 20.3125 28 18.9111 28 17.1875V3.125C28 1.40137 26.6049 0 24.8889 0H3.11111ZM24.8889 3.125V14.0625H3.11111V3.125H24.8889Z"
                                        fill="#9A9FB5"
                                    />
                                </svg>
                                <span style={menuText} className="menu-text">
                                    Devices
                                </span>
                            </Link>
                        </li>
                        <li>
                            <Link
                                to="/teams"
                                className={
                                    location === '/teams' ? 'link_active' : 'navigation_link'
                                }>
                                <svg
                                    width="25"
                                    height="25"
                                    viewBox="0 0 32 25"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M7.2 7.8125C4.99 7.8125 3.2 6.06445 3.2 3.90625C3.2 1.74805 4.99 0 7.2 0C9.41 0 11.2 1.74805 11.2 3.90625C11.2 6.06445 9.41 7.8125 7.2 7.8125ZM25.6 7.8125C23.39 7.8125 21.6 6.06445 21.6 3.90625C21.6 1.74805 23.39 0 25.6 0C27.81 0 29.6 1.74805 29.6 3.90625C29.6 6.06445 27.81 7.8125 25.6 7.8125ZM0 14.585C0 11.709 2.39 9.375 5.335 9.375H7.47C8.265 9.375 9.02 9.5459 9.7 9.84863C9.635 10.2002 9.605 10.5664 9.605 10.9375C9.605 12.8027 10.445 14.4775 11.77 15.625C11.76 15.625 11.75 15.625 11.735 15.625H1.065C0.48 15.625 0 15.1563 0 14.585ZM20.265 15.625C20.255 15.625 20.245 15.625 20.23 15.625C21.56 14.4775 22.395 12.8027 22.395 10.9375C22.395 10.5664 22.36 10.2051 22.3 9.84863C22.98 9.54102 23.735 9.375 24.53 9.375H26.665C29.61 9.375 32 11.709 32 14.585C32 15.1611 31.52 15.625 30.935 15.625H20.265ZM20.8 10.9375C20.8 13.5254 18.65 15.625 16 15.625C13.35 15.625 11.2 13.5254 11.2 10.9375C11.2 8.34961 13.35 6.25 16 6.25C18.65 6.25 20.8 8.34961 20.8 10.9375ZM6.4 23.6963C6.4 20.1025 9.385 17.1875 13.065 17.1875H18.935C22.615 17.1875 25.6 20.1025 25.6 23.6963C25.6 24.4141 25.005 25 24.265 25H7.735C7 25 6.4 24.4189 6.4 23.6963Z"
                                        fill="#9A9FB5"
                                    />
                                </svg>
                                <span style={menuText} className="menu-text">
                                    Teams
                                </span>
                            </Link>
                        </li>
                        <li>
                            <Link
                                className={
                                    location === '/tasks' ? 'link_active' : 'navigation_link'
                                }
                                to="/tasks">
                                <svg
                                    width="25"
                                    height="26"
                                    viewBox="0 0 30 26"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M8.91417 0.37218C9.49419 0.907849 9.54106 1.81668 9.01963 2.41253L4.80129 7.22753C4.5435 7.52245 4.18026 7.69699 3.79358 7.70301C3.40689 7.70903 3.03779 7.55856 2.76243 7.2817L0.413046 4.8742C-0.137682 4.30844 -0.137682 3.39359 0.413046 2.83385C0.963773 2.2741 1.85431 2.26809 2.39918 2.83385L3.69398 4.16399L6.92218 0.480518C7.44361 -0.115338 8.32829 -0.163488 8.90831 0.37218H8.91417ZM8.91417 10.0022C9.49419 10.5378 9.54106 11.4467 9.01963 12.0425L4.80129 16.8575C4.5435 17.1524 4.18026 17.327 3.79358 17.333C3.40689 17.339 3.03779 17.1886 2.76243 16.9117L0.413046 14.5042C-0.137682 13.9384 -0.137682 13.0236 0.413046 12.4638C0.963773 11.9041 1.85431 11.8981 2.39918 12.4638L3.69398 13.794L6.92218 10.1105C7.44361 9.51466 8.32829 9.46651 8.90831 10.0022H8.91417ZM13.1266 3.85102C13.1266 2.7857 13.9645 1.92502 15.0015 1.92502H28.1252C29.1622 1.92502 30 2.7857 30 3.85102C30 4.91633 29.1622 5.77701 28.1252 5.77701H15.0015C13.9645 5.77701 13.1266 4.91633 13.1266 3.85102ZM13.1266 13.481C13.1266 12.4157 13.9645 11.555 15.0015 11.555H28.1252C29.1622 11.555 30 12.4157 30 13.481C30 14.5463 29.1622 15.407 28.1252 15.407H15.0015C13.9645 15.407 13.1266 14.5463 13.1266 13.481ZM9.37702 23.111C9.37702 22.0457 10.2148 21.185 11.2518 21.185H28.1252C29.1622 21.185 30 22.0457 30 23.111C30 24.1763 29.1622 25.037 28.1252 25.037H11.2518C10.2148 25.037 9.37702 24.1763 9.37702 23.111ZM2.81515 26C1.26257 26 0.00292938 24.706 0.00292938 23.111C0.00292938 21.516 1.26257 20.222 2.81515 20.222C4.36774 20.222 5.62738 21.516 5.62738 23.111C5.62738 24.706 4.36774 26 2.81515 26Z"
                                        fill="#9A9FB5"
                                    />
                                </svg>
                                <span style={menuText} className="menu-text">
                                    Tasks
                                </span>
                            </Link>
                        </li>
                        <li>
                            <Link
                                to="/audit"
                                className={
                                    location === '/audit' ? 'link_active' : 'navigation_link'
                                }>
                                <svg
                                    width="25"
                                    height="28"
                                    viewBox="0 0 27 28"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M21.9375 10.9688C21.9375 13.3893 21.1518 15.6252 19.8281 17.4393L26.5043 24.1207C27.1635 24.7799 27.1635 25.8504 26.5043 26.5096C25.8451 27.1688 24.7746 27.1688 24.1154 26.5096L17.4393 19.8281C15.6252 21.157 13.3893 21.9375 10.9688 21.9375C4.90957 21.9375 0 17.0279 0 10.9688C0 4.90957 4.90957 0 10.9688 0C17.0279 0 21.9375 4.90957 21.9375 10.9688ZM10.9688 18.5625C15.1611 18.5625 18.5625 15.1611 18.5625 10.9688C18.5625 6.77637 15.1611 3.375 10.9688 3.375C6.77637 3.375 3.375 6.77637 3.375 10.9688C3.375 15.1611 6.77637 18.5625 10.9688 18.5625Z"
                                        fill="#9A9FB5"
                                    />
                                </svg>
                                <span style={menuText} className="menu-text">
                                    Audit
                                </span>
                            </Link>
                        </li>
                        <li>
                            <Link className="navigation_link" to="/">
                                <svg
                                    width="25"
                                    height="26"
                                    viewBox="0 0 21 37"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M10.3572 0C11.6294 0 12.6572 1.0334 12.6572 2.3125V4.89238C12.7722 4.90684 12.88 4.92129 12.995 4.94297C13.7569 5.05859 16.0209 5.42715 16.9553 5.66563C18.1844 5.97637 18.9319 7.23379 18.6228 8.47676C18.3138 9.71973 17.0631 10.4641 15.8269 10.1533C15.1584 9.97988 13.1244 9.64746 12.3122 9.52461C10.005 9.17773 8.02845 9.35117 6.67001 9.87871C5.3547 10.3846 4.80845 11.1 4.6647 11.9021C4.52814 12.6754 4.62876 13.1162 4.75814 13.3908C4.8947 13.6799 5.16063 14.0051 5.68532 14.3592C6.8497 15.1396 8.63939 15.6527 10.9538 16.2887L11.155 16.3465C13.1963 16.9029 15.6975 17.5895 17.5519 18.8252C18.5653 19.5045 19.5141 20.4223 20.1034 21.6941C20.7 22.9805 20.8294 24.4258 20.5563 25.965C20.0603 28.7111 18.1772 30.5467 15.8413 31.5078C14.8566 31.9125 13.7856 32.1727 12.65 32.3027V34.6875C12.65 35.9666 11.6222 37 10.35 37C9.07782 37 8.05001 35.9666 8.05001 34.6875V32.1654C8.02126 32.1582 7.98532 32.1582 7.95657 32.151H7.9422C6.18845 31.8764 3.30626 31.1176 1.36564 30.2504C0.20126 29.7301 -0.31624 28.3643 0.20126 27.2008C0.71876 26.0373 2.0772 25.5098 3.23439 26.0301C4.73657 26.7021 7.20907 27.3598 8.63939 27.591C10.9322 27.9307 12.8225 27.7355 14.1019 27.208C15.3166 26.7094 15.87 25.9867 16.0281 25.1195C16.1647 24.3463 16.0641 23.9055 15.9347 23.6309C15.7981 23.3418 15.5322 23.0166 15.0075 22.6625C13.8431 21.882 12.0534 21.3689 9.73907 20.733L9.53782 20.6752C7.49657 20.1187 4.99532 19.4322 3.14095 18.1965C2.12751 17.5172 1.17876 16.5994 0.589385 15.3275C-0.00717731 14.0412 -0.136552 12.5959 0.136573 11.0566C0.654073 8.24551 2.66657 6.45332 5.03845 5.54277C5.97282 5.18145 6.99345 4.9502 8.0572 4.82012V2.3125C8.0572 1.0334 9.08501 0 10.3572 0Z"
                                        fill="#9A9FB5"
                                    />
                                </svg>
                                <span style={menuText} className="menu-text">
                                    Payments
                                </span>
                            </Link>
                        </li>
                        {location !== '/scripts' && location !== '/term' ? (
                            <li>
                                <Link className="navigation_link" to="/scripts">
                                    <svg
                                        width="25"
                                        height="25"
                                        viewBox="0 0 28 25"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M1.17114 0C0.522132 0 0 0.522461 0 1.17188C0 1.82129 0.522132 2.34375 1.17114 2.34375H3.71348L6.65596 17.7979C6.76332 18.3496 7.24641 18.75 7.80758 18.75H23.8131C24.4621 18.75 24.9843 18.2275 24.9843 17.5781C24.9843 16.9287 24.4621 16.4062 23.8131 16.4062H8.77865L8.33459 14.0625H23.8034C24.5012 14.0625 25.116 13.5986 25.3063 12.9248L27.9414 3.5498C28.2195 2.55371 27.4729 1.5625 26.4384 1.5625H5.95328L5.83617 0.952148C5.72881 0.400391 5.24572 0 4.68455 0H1.17114ZM8.58834 25C9.88147 25 10.9306 23.9502 10.9306 22.6562C10.9306 21.3623 9.88147 20.3125 8.58834 20.3125C7.29521 20.3125 6.24607 21.3623 6.24607 22.6562C6.24607 23.9502 7.29521 25 8.58834 25ZM24.9843 22.6562C24.9843 21.3623 23.9351 20.3125 22.642 20.3125C21.3489 20.3125 20.2997 21.3623 20.2997 22.6562C20.2997 23.9502 21.3489 25 22.642 25C23.9351 25 24.9843 23.9502 24.9843 22.6562Z"
                                            fill="#9A9FB5"
                                        />
                                    </svg>
                                    <span style={menuText} className="menu-text">
                                        Marketplace
                                    </span>
                                </Link>
                            </li>
                        ) : (
                            <div className="scripts_link_container">
                                <li>
                                    <Link className="market_link_active" to="/scripts">
                                        <svg
                                            width="25"
                                            height="25"
                                            viewBox="0 0 28 25"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M1.17114 0C0.522132 0 0 0.522461 0 1.17188C0 1.82129 0.522132 2.34375 1.17114 2.34375H3.71348L6.65596 17.7979C6.76332 18.3496 7.24641 18.75 7.80758 18.75H23.8131C24.4621 18.75 24.9843 18.2275 24.9843 17.5781C24.9843 16.9287 24.4621 16.4062 23.8131 16.4062H8.77865L8.33459 14.0625H23.8034C24.5012 14.0625 25.116 13.5986 25.3063 12.9248L27.9414 3.5498C28.2195 2.55371 27.4729 1.5625 26.4384 1.5625H5.95328L5.83617 0.952148C5.72881 0.400391 5.24572 0 4.68455 0H1.17114ZM8.58834 25C9.88147 25 10.9306 23.9502 10.9306 22.6562C10.9306 21.3623 9.88147 20.3125 8.58834 20.3125C7.29521 20.3125 6.24607 21.3623 6.24607 22.6562C6.24607 23.9502 7.29521 25 8.58834 25ZM24.9843 22.6562C24.9843 21.3623 23.9351 20.3125 22.642 20.3125C21.3489 20.3125 20.2997 21.3623 20.2997 22.6562C20.2997 23.9502 21.3489 25 22.642 25C23.9351 25 24.9843 23.9502 24.9843 22.6562Z"
                                                fill="#9A9FB5"
                                            />
                                        </svg>
                                        <span style={menuText} className="menu-text">
                                            Marketplace
                                        </span>
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        className={
                                            location === '/scripts'
                                                ? 'scripts_active nav_scripts'
                                                : 'navigation_link nav_scripts'
                                        }
                                        to="/scripts">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="28"
                                            height="28"
                                            viewBox="0 0 28 28"
                                            fill="none">
                                            <path
                                                d="M16.3333 2.33398H7C6.38116 2.33398 5.78767 2.57982 5.35009 3.0174C4.9125 3.45499 4.66667 4.04848 4.66667 4.66732V23.334C4.66667 23.9528 4.9125 24.5463 5.35009 24.9839C5.78767 25.4215 6.38116 25.6673 7 25.6673H21C21.6188 25.6673 22.2123 25.4215 22.6499 24.9839C23.0875 24.5463 23.3333 23.9528 23.3333 23.334V9.33398L16.3333 2.33398Z"
                                                stroke="#9A9FB5"
                                                strokeWidth="3"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                            <path
                                                d="M16.3333 2.33398V9.33398H23.3333"
                                                stroke="#9A9FB5"
                                                strokeWidth="3"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                            <path
                                                d="M10.5 17.5H17.5"
                                                stroke="#9A9FB5"
                                                strokeWidth="3"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                        </svg>
                                        <span style={menuText} className="menu-text">
                                            Scripts
                                        </span>
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        className={
                                            location === '/term'
                                                ? 'scripts_active nav_scripts'
                                                : 'navigation_link nav_scripts'
                                        }
                                        to="/term">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none">
                                            <path
                                                d="M4 17L10 11L4 5"
                                                stroke="#9A9FB5"
                                                strokeWidth="3"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                            <path
                                                d="M12 19H20"
                                                stroke="#9A9FB5"
                                                strokeWidth="3"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                        </svg>
                                        <span style={menuText} className="menu-text">
                                            Term
                                        </span>
                                    </Link>
                                </li>
                            </div>
                        )}
                    </ul>
                </div>
            </aside>

            <div
                className={`${className ? className + 'cont' : ''} ${
                    menuType === 'menu-max' ? 'section_max' : 'section_min'
                }`}>
                {children}
            </div>
        </div>
    );
});

export default MainLayout;
