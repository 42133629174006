import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { makeFetch } from '../helpers';

export type systemTypes = 'Windows' | 'macOS' | 'Linux' | 'Raspberry'

export type DeviceType = {
    id: string;
    organization_id: string;
    scripts: object;
    device_status: string;
    device_name: string;
    system: systemTypes;
    cluster_id: string;
    online: boolean;
    last_operated: null | number
};

export type AuditType = {
    block_id: string;
    data: string;
    data_format: string;
    data_tx: string;
    device_id: string;
    nonce: number;
    timestamp: number;
    timestamp_hash: number;
    tx: string;
    user_id: string;
    device_name?: string;
    system: systemTypes;

};

type InitialType = {
    loading: boolean;
    devices: DeviceType[];
    audits: AuditType[];
    auditIsLoading: boolean,
    totalCount: number,
    permissionsError: null  | string
};

const initialState: InitialType = {
    loading: false,
    auditIsLoading: false,
    devices: [],
    audits: [],
    totalCount:1,
    permissionsError: null
};

type FetchAuditType = {
    orgId: string;
    limit: number;
    offset: number;
}


export const fetchDevicesId = createAsyncThunk('devices/get', async (orgId: string) => {
    if (!orgId) {
        return {success: false, data: 'Org id is missing'};
    }

    const { success, data } = await makeFetch(
        process.env.REACT_APP_ORG_URL + '/org/get/devices',
        'POST',
        { 
            id: orgId,
            limit: 20,
            offset: 0,
         },
        String(localStorage.getItem('__t'))
    );
    const devArray: DeviceType[] = [];
    const getDeviceInfo = async (id: string) => {
        const { success, data } = await makeFetch(
            `${process.env.REACT_APP_DEVICES_URL}/devices/byid/${id}/${orgId}`,
            'GET',
            null,
            String(localStorage.getItem('__t'))
        );
        if (success) {
            devArray.push(data);
        }
    };
    if (success) {
        await Promise.all(
            data.map((entry: string) => {
                return getDeviceInfo(entry);
            })
        );
    }

    const sortedArr = devArray.sort((a, b) => {
        const idA = a.id;
        const idB = b.id;
        if (idA < idB) {
            return -1;
        }
        if (idA > idA) {
            return 1;
        }
        return 0;
    });

    if (success) {
        return {success, data: sortedArr};
    } else {
        console.log(data)
        return data
    }
});


export const fetchAuditsFirst = createAsyncThunk('audit/get', async ({orgId, limit, offset}: FetchAuditType) => {
    if (!orgId) {
        return [];
    }
    const { success, data } = await makeFetch(
        `${process.env.REACT_APP_AUDIT_URL}/audit/all`,
        'POST',
        {
            org_id: orgId,
            limit: limit,
            offset: offset 
        },
        String(localStorage.getItem('__t'))
    );
    if (success) {
        return data
    } else {
        return []
    }
});



export const deviceSlice = createSlice({
    name: 'devices',
    initialState,
    reducers: {
        setAudits(state, {payload}) {
            state.audits = payload
        },
        setAuditLoading(state, {payload}) {
            state.auditIsLoading = payload
        },
        setTotalCount(state, {payload}) {
            state.totalCount = payload
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchDevicesId.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchDevicesId.fulfilled, (state, {payload}) => {
                state.loading = false;
                if (payload.success) {
                    state.devices = payload.data;
                    state.permissionsError = null
                } else {
                    state.devices = [];
                    state.permissionsError = payload
                }
            })
            .addCase(fetchAuditsFirst.pending, (state) => {
                state.auditIsLoading = true;
            })
            .addCase(fetchAuditsFirst.fulfilled, (state, {payload}) => {
                state.auditIsLoading = false;
                state.audits = payload.result.sort((a: any, b: any) => b.timestamp - a.timestamp);
                state.totalCount = payload.total_count
            })
    }
});

export default deviceSlice.reducer;
export const {setAudits, setAuditLoading, setTotalCount} = deviceSlice.actions;
