import React, { memo, useState } from 'react'

import CreateClusterPopup from '../../devicesComponent/createClusterPopup/CreateClusterPopup';

const CreateClusterButton = memo(() => {
  const [active, setActive] = useState<boolean>(false);

    return (
        <>
            <button className="add_devices_button_cont" onClick={() => setActive(true)}>
                <img className="devices_button_img" src="/img/add_icon.svg" alt="" />
                <div className="add_devices_text">Create new cluster</div>
            </button>
            <CreateClusterPopup active={active} setActive={setActive}/>
        </>
    );
})

export default CreateClusterButton
