export const permissions = [
    {
        "name": "View devices list",
        "description": "Access to view device(s) in device list",
        "action": ["devices:view"],
        "fields": []
    },
    {
        "name": "Add new device to organization",
        "description": "Access to add new device in organization",
        "action": ["devices:add"],
        "fields": []
    },
    {
        "name": "Add device only with specified OS",
        "description": "Access to add device in organization only with specified OS",
        "action": ["devices:add_only_with_os"],
        "fields": []
    },
    {
        "name": "Remove device from organization",
        "description": "Access to remove device from organization",
        "action": ["devices:remove"],
        "fields": []
    },
    
    {
        "name": "Add device to a cluster",
        "description": "Acess to add devices in cluster",
        "action": ["devices:clusters:assign_device"],
        "fields": []
    },
    {
        "name": "View cluster list",
        "description": "Access to view cluster list",
        "action": ["devices:clusters:view"],
        "fields": []
    },
    {
        "name": "Crete new cluster",
        "description": "Access to create new clusters in organization",
        "action": ["devices:clusters:create"],
        "fields": []
    },
    {
        "name": "Remove cluster",
        "description": "Access to remove clusters from organization",
        "action": ["devices:clusters:remove"],
        "fields": []
    },
    {
        "name": "Update cluster settings",
        "description": "Access to update settings in cluster",
        "action": ["devices:clusters:settings"],
        "fields": []
    },
    {
        "name": "Remove device from cluster",
        "description": "Access to remove device from cluster's device list",
        "action": ["devices:clusters:remove_device"],
        "fields": []
    },

    {
        "name": "View health chart (24h/7d)",
        "description": "Access to view device activity statistic",
        "action": ["device:view_health_chart"],
        "fields": []
    },
    {
        "name": "Add scripts to device",
        "description": "Access to add scripts that added to organization to device",
        "action": ["device:add_script"],
        "fields": []
    },
    {
        "name": "Remove scripts from device",
        "description": "Access to remove scripts from device",
        "action": ["device:remove_script"],
        "fields": []
    },
    {
        "name": "Run scripts on device",
        "description": "Access to run scripts on device, or create scheduled scripts",
        "action": ["device:run_script"],
        "fields": []
    },
    {
        "name": "View device latest activity",
        "description": "Access to view device latest activity",
        "action": ["device:view_activity"],
        "fields": []
    },
    {
        "name": "Device terminal access",
        "description": "Access to run terminal commands on device",
        "action": ["device:access_terminal"],
        "fields": []
    },
    {
        "name": "Change device settings",
        "description": "Access to change device settings",
        "action": ["device:settings"],
        "fields": []
    },
    {
        "name": "View device only with axact id",
        "description": "Access to view device only by specified id",
        "action": ["device:view"],
        "fields": []
    },
    // Teams
    {
        "name": "View teams",
        "description": "Access to view teams page",
        "action": ["teams:view"],
        "fields": []
    },
    {
        "name": "Invite user",
        "description": "Access to invite user function",
        "action": ["teams:invite_user_to_org"],
        "fields": []
    },
    {
        "name": "Remove user",
        "description": "Access to remove user function",
        "action": ["teams:remove_user_from_org"],
        "fields": []
    },
    {
        "name": "Team invite user",
        "description": "Access to invite user to team",
        "action": ["teams:invite_user"],
        "fields": []
    },
    {
        "name": "Team remove user",
        "description": "Access to remove user from team",
        "action": ["teams:remove_user"],
        "fields": []
    },
    {
        "name": "Create team",
        "description": "Access to create team function",
        "action": ["teams:create_team"],
        "fields": []
    },
    {
        "name": "Create temporary team",
        "description": "Access to create temporary team",
        "action": ["teams:create_temporary_team"],
        "fields": []
    },
    {
        "name": "Remove team",
        "description": "Access to create team function",
        "action": ["teams:remove_team"],
        "fields": []
    },
    {
        "name": "Remove temporary team",
        "description": "Access to remove temporary team",
        "action": ["teams:remove_temporary_team"],
        "fields": []
    },
    {
        "name": "Team settings",
        "description": "Access to team settings",
        "action": ["teams:settings"],
        "fields": []
    },
    // Tasks
    {
        "name": "Create new task",
        "description": "Access to create new tasks",
        "action": ["tasks:create"],
        "fields": []
    },
    {
        "name": "Delete task",
        "description": "Access to delete task from organization",
        "action": ["tasks:remove"],
        "fields": []
    },
    {
        "name": "View task list",
        "description": "Access to view task list/kanban",
        "action": ["tasks:view"],
        "fields": []
    },
    {
        "name": "Update task",
        "description": "Access to update task",
        "action": ["tasks:update"],
        "fields": []
    },
    // Audit
    {
        "name": "View audit",
        "description": "Access to view audit page",
        "action": ["audit:view"],
        "fields": []
    },

    // Marketplace 
    {
        "name": "Add script to marketplace",
        "description": "Access to add script to marketplace",
        "action": ["marketplace:add"],
        "fields": []
    },
    {
        "name": "Remove script from marketplace",
        "description": "Access to remove script from marketplace",
        "action": ["marketplace:remove"],
        "fields": []
    },
    {
        "name": "Add script from marketplace to org",
        "description": "Access to add script from marketplace to org",
        "action": ["marketplace:add_to_org"],
        "fields": []
    },
    {
        "name": "Remove script from org",
        "description": "Access to remove script from org",
        "action": ["marketplace:remove_from_org"],
        "fields": []
    }
]



export const permissionsName = {
    devices: {
        view:'devices:view',
        add: "devices:add",
        add_only_with_os:'devices:add_only_with_os', // NEED IMPLEMENT
        remove: 'devices:remove',
        clusters: {
            view: 'devices:clusters:view',
            create: 'devices:clusters:create',
            remove: 'devices:clusters:remove',
            settings: 'devices:clusters:settings',
            assign_device: 'devices:clusters:assign_device',
            remove_device: 'devices:clusters:remove_device',
        },
    },
    device: {
        view: 'device:view',
        view_health_chart:'device:view_health_chart',
        add_script: 'device:add_script',
        remove_script: 'device:remove_script',
        run_script: 'device:run_script',
        view_activity: 'device:view_activity',
        access_terminal: 'device:access_terminal',
        settings: 'device:settings',
    },
    teams: {
        teams_view: 'teams:view',
        invite_user_to_org: 'teams:invite_user_to_org',
        remove_user_from_org: 'teams:remove_user_from_org',
        invite_user: 'teams:invite_user',
        remove_user: 'teams:remove_user',
        create_team: 'teams:create_team',
        create_temporary_team: 'teams:create_temporary_team',
        remove_team: 'teams:remove_team',
        remove_temporary_team: 'teams:remove_temporary_team',
        settings: 'teams:settings'
    },
    tasks: {
        create: 'tasks:create',
        remove: 'tasks:remove',
        view: 'tasks:view',
        update: 'tasks:update',
    },
    audit: 'audit:view',
    marketplace: {
        add: 'marketplace:add',
        remove: 'marketplace:remove',
        add_to_org: 'marketplace:add_to_org',
        remove_from_org: 'marketplace:remove_from_org',
    }
}