import React, { memo, useCallback, useEffect, useState } from 'react';

import { useAppSelector } from '../../../hooks/redux';
import { makeFetch } from '../../../redux/helpers';
import { fetchClusters } from '../../../redux/reducers/ClustersSlice';
import { useAppDispatch } from '../../../redux/store';
import { createModal } from '../../Modals';
import ModalLayout from '../../sectionsFilling/modalLayout/ModalLayout';

import './CreateClusterPopup.css';

type PropsType = {
    active: boolean;
    setActive: any;
};

const CreateClusterPopup = memo(({ active, setActive }: PropsType) => {
    const { lastWisitedOrg } = useAppSelector((state) => state.userReducer);

    const [name, setName] = useState<string>('');

    const dispatch = useAppDispatch()

    const createClusterAction = useCallback(async () => {
        const { success, data } = await makeFetch(
            process.env.REACT_APP_ORG_URL + '/org/cluster/add',
            'POST',
            { 
                orgId: lastWisitedOrg, 
                name: name 
            },
            String(localStorage.getItem('__t'))
        );
        if (success) {
            setActive(false);
            createModal({ error: false, success: true, message: 'Cluster was succesfully created' });
            setName("")
            dispatch(fetchClusters(lastWisitedOrg))
        } else {
            console.log(data);
            createModal({ error: true, message: data });
        }
    }, [name, lastWisitedOrg]);


    return (
        <ModalLayout
            active={active}
            setActive={setActive}
            icon={'monitor'}
            sectionName={'Create cluster'}
            maximize={false}
            headColor="blue">
            <div className="create_cluster_popup_cont">
                <div>
                    <div className="invite_new_user_inner">
                        <div className="sect_name_text">Cluster Name:</div>
                        <input
                            className="invite_input"
                            type="text"
                            placeholder="Cluster name"
                            value={name}
                            onChange={(e: any) => setName(e.target.value)}
                        />
                    </div>
                </div>
                <div className="save_button_cont">
                    <button
                        disabled={!name}
                        className="create_cluster_button"
                        onClick={createClusterAction}>
                        Create cluster
                    </button>
                </div>
            </div>
        </ModalLayout>
    );
});

export default CreateClusterPopup;
