import React from "react";
import { memo, useState } from "react";

import "./Tasks.css"


type PropTypes = {
    status: string;
}


const TaskStatus = memo(({ status }: PropTypes) => {
    if (status === "in_work") {
        return (
            <>
                <img src="./img/clock.svg" alt="in work" />
                <div className="in_work">IN WORK</div>
            </>
        )
    }
    if (status === "expired") {
        return (
            <>
                <img src="./img/x-circle.svg" alt="" />
                <div className="expired">EXPIRED</div>
            </>
        )
    }
    if (status === "done") {
        return (
            <>
                <img src="./img/done.svg" alt="" />
                <div className="done">DONE</div>
            </>
        )
    }
    else return <></>
})

const TaskStatusImg = memo(({ status }: PropTypes) => {
    if (status === "in_work") {
        return (
            <>
                <img src="./img/file-text.svg" alt="" />
            </>
        )
    }
    if (status === "expired") {
        return (
            <>
                <img src="./img/file-text-expired.svg" alt="ss" />
            </>
        )
    }
    if (status === "done") {
        return (
            <>
                <img src="./img/file-text-done.svg" alt="" />
            </>
        )
    }
    else return <></>
})

const Tasks = memo(({ status }: PropTypes) => {

    const [logType, setLogType] = useState("tasks_min")

    const logAction = () => {
        if (logType === 'tasks_min') {
            setLogType("tasks_max")
        } else {
            setLogType("tasks_min")
        }
    }


    


    return (
        <div className="tasks_container">
            <div className={logType === "tasks_min" ? "tasks_min" : "tasks_max"}>
                <div className="tasks_info" onClick={() => logAction()}  >
                    <div style={{ display: "flex" }}>
                        <TaskStatusImg status={status}/>
                        <div className="tasks_text">
                            <div className="devices_text_h">Create production version of the site</div>
                            <div className="tasks_status_adaptive">
                                <TaskStatus status={status} />
                            </div>
                            <div className="devices_text_s">Created: 2022/11/20 23:12 - 2022/11/21 12:00</div>
                        </div>
                    </div>
                    <div className="tasks_status">
                        <div className="tasks_time">
                            <TaskStatus status={status} />
                        </div>
                        <img className="chevron" src="./img/chevron-down.svg" alt="up" />
                    </div>
                </div>
                <div className="tasks_fill">
                    <div className="tasks_fill_info">
                        <div className="tasks_fill_info_first">Executor:</div>
                        <img src="./img/avatar.svg" alt="" />
                        <div className="tasks_fill_info_second">Eugene M</div>
                    </div>
                    <div className="tasks_fill_info">
                        <div className="tasks_fill_info_first">Creator:</div>
                        <img src="./img/avatar.svg" alt="" />
                        <div className="tasks_fill_info_second">Yurii L</div>
                    </div>
                    <div className="tasks_fill_info">
                        <div className="tasks_fill_info_first">For device:</div>
                        <div className="tasks_fill_info_second tasks_devices">
                            <div className="linux_dev">Linux E...</div>
                            <div className="windows_dev">Win...</div>
                        </div>
                    </div>
                </div>
                <div className="tasks_button_container">
                    <div className="tasks_button_done">
                        <img src="./img/check-circle.svg" alt="check" />
                        <div>Mark as done</div>
                    </div>
                    <div className="tasks_button_edit">
                        <img src="./img/edit-icon.svg" alt="" />
                        <div>Edit task</div>
                    </div>
                </div>
            </div>

        </div >
    )
})

export default Tasks