import { fromBaseString, toBaseString } from "../../../helpers/base64"

export enum MessageType {
    //configuration
	CONF = "CONF",
    //connection accept
	ACPT = "ACPT",
    //can not provide connection to device
	CNDE = "CNDE",
	//shell output
	SOUT = "SOUT",
    // shell ended command execution
	ShellEND = "SHND",
    // shell command execution error
	ShellERR = "SERR",
    // contains of current folder structure
	PWDChange = "PWDC",
    // sdtin write
    ShellIn = "SDIN",
    //commad continuing execution
    ALIV = "ALIV",
	//start command execution
	STRT = "STRT",
	//finalization command execution
	FINL = "FINL",
	//error message
	ERNO = "ERNO",
    //end of output
    OEND = "OEND",

    PING = "PING",
    PONG = "PONG"
}

type CONFMessage = {
	user: string
	device: string
}

type STRTMessage = string

export type SOUTMessage = {
    command: string
	output: string
	time: string
}

interface OutcomeMessage {
    $type: MessageType,
    data: STRTMessage | CONFMessage
}

export class Message {
    public $type: MessageType
    private data: unknown

    constructor (_init: string) {
        const encoded = fromBaseString(_init)
        const de = JSON.parse(encoded)
        this.$type = de.$type
        this.data = de.data
    }

    public dataGetter<T>(): T {
        const dataAny = this.data as any
        if (dataAny["output"] != undefined) {
            dataAny.output = this.unescapeANSICodes(dataAny.output)
        }
        return dataAny as T;
    }

    private unescapeANSICodes(input: string): string {
        input = input.replace(/\\x1b/g, "\x1b")
        input = input.replace(/\\u001b/g, "\u001b")
        input = input.replace(/\\033/g, "\x1b")
        return input
    }
}

export class MessageBuilder {
    private type: MessageType
    private data: OutcomeMessage | undefined;

    constructor (_type: MessageType) {
        this.type = _type;
    }

    public buildConfig(data: CONFMessage): MessageBuilder {
        this.data = {
            $type: this.type,
            data
        }
        return this;
    }

    public buildStart(data: STRTMessage): MessageBuilder {
        this.data = {
            $type: this.type,
            data
        }
        return this;
    }

    public buildPing(data: string): MessageBuilder {
        this.data = {
            $type: this.type,
            data
        }

        return this;
    }

    public buildFinalize(): MessageBuilder {
        this.data = {
            $type: this.type,
            data: ""
        }

        return this
    }

    public buildStdin(data: string): MessageBuilder {
        this.data = {
            $type: this.type,
            data
        }
        return this
    }

    public toEncodedJSON(): string {
        if (this.data == undefined) {
            throw new Error("Message data was not provided")
        }

        return toBaseString(JSON.stringify(this.data));
    }
}
